import { useNavigation } from "@react-navigation/native";
import { Dimensions, Image, Text, TouchableOpacity, View } from "react-native";
import { API_URL } from "../../constants/api_url";
import colors from "../../constants/Colors";

const MaidButton = ({ maid }) => {
  const windowWidth = Dimensions.get("window").width;
  const navigation = useNavigation();

  const { schedule_color, display_color } = maid;

  return (
    <View
      key={maid.id}
      style={{
        width: (windowWidth - 20 - 20) / 5,
        justifyContent: "space-between",
        alignItems: "center",
        // backgroundColor: 'red',
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("Cafe.Tab", {
            screen: "Cafe.MaidDetail",
            params: {
              maidId: maid.id,
              title: maid.name,
              cafeId: maid.cafe_id,
              cafeTitle: maid.cafe_title,
            },
          });
        }}
      >
        <View
          style={{
            width: (windowWidth - 20) / 5 - 10,
            height: (windowWidth - 20) / 5 - 10,
            overflow: "hidden",

            // backgroundColor: "pink",
            // borderRadius: 50,
            // borderRadius: "50%",

            borderRadius: ((windowWidth - 20) / 5 - 10) / 2,
            // backgroundColor: "#a0a0a0",

            borderColor: display_color ? display_color : colors.main,
            borderWidth: display_color ? 3 : 0,
            backgroundColor: display_color ? display_color + "1A" : "#fff",
          }}
        >
          {maid.thumbnail_image ? (
            <Image
              source={{ uri: API_URL + "/" + maid.thumbnail_image }}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : null}
        </View>
        <Text
          style={{
            // color: "blue",
            textAlign: "center",
            // color: maid.display_color ? maid.display_color : "#505050",
            // color: "#505050",
            color: schedule_color ? schedule_color : "#505050",
          }}
          adjustsFontSizeToFit={true}
          numberOfLines={2}
        >
          {maid.name}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const MaidDisplay = ({ maid }) => {
  const windowWidth = Dimensions.get("window").width;
  const navigation = useNavigation();

  const { maid_name, color, title } = maid;

  return (
    <View
      // key={maid.id}
      style={{
        width: (windowWidth - 20 - 20) / 5,
        justifyContent: "space-between",
        alignItems: "center",
        // backgroundColor: 'red',
      }}
    >
      {/* <TouchableOpacity
        onPress={() => {
          navigation.navigate("Cafe.Tab", {
            screen: "Cafe.MaidDetail",
            params: {
              maidId: maid.id,
              title: maid.name,
            },
          });
        }}
      > */}
      <View
        style={{
          width: (windowWidth - 20) / 5 - 10,
          height: (windowWidth - 20) / 5 - 10,
          overflow: "hidden",

          // backgroundColor: "pink",
          // borderRadius: 50,
          // borderRadius: "50%",
          borderRadius: ((windowWidth - 20) / 5 - 10) / 2,
          borderColor: color ? color : colors.main,
          borderWidth: 3,

          backgroundColor: color ? color + "1A" : "#fff",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            color: color ? color : colors.main,
            transform: [{ rotate: "-30deg" }],
            fontSize: 17,
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {title ? title : "限定"}
        </Text>
      </View>
      <Text
        style={{
          // color: "blue",
          textAlign: "center",
          color: "#505050",
        }}
        adjustsFontSizeToFit={true}
        numberOfLines={2}
      >
        {maid_name}
      </Text>
      {/* </TouchableOpacity> */}
    </View>
  );
};

export default MaidButton;

export { MaidDisplay };
