import { Text, View } from "react-native";
import { WEEKDAYS_REF_EN } from "../../constants/weekdays";

const WeekdayCard = ({ date, selected }) => {
  return (
    <View
      style={{
        backgroundColor: "#fff",
        borderColor: "#000",
        borderRadius: 10,
        borderWidth: selected ? 3 : 1,
        marginHorizontal: 5,
        justifyContent: "center",
        minWidth: 80,
      }}
    >
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: "#000",
          paddingHorizontal: 8,
          paddingVertical: 5,
        }}
      >
        <Text
          style={{
            color:
              date.getDay() == 0 || date.getDay() == 6 ? "#ff0000" : "#000",
            fontSize: 23,
            textAlign: "center",
            fontWeight: "800",
          }}
        >
          {WEEKDAYS_REF_EN[date.getDay()].toUpperCase()}
        </Text>
      </View>
      <View
        style={{
          paddingHorizontal: 8,
          paddingVertical: 5,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            color: "#000",
            fontSize: 40,
            textAlign: "center",
          }}
        >
          {date.getDate()}
        </Text>
      </View>
    </View>
  );
};

export default WeekdayCard;
