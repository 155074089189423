import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import CafeListScreen from "../screens/Cafes/CafeListScreen";
import FavouriteComingSoonScreen from "../screens/Favourites/FavouriteComingSoonScreen";
import MemberComingSoonScreen from "../screens/Member/MemberComingSoonScreen";
import CafeNavigator from "./CafeNavigator";
import ScheduleNavigator from "./ScheduleNavigator";
import { Feather } from "@expo/vector-icons";
import Colors from "../constants/Colors";
import FavouriteNavigator from "./FavouriteNavigator";
import MainNavigator from "./MainNavigator";
const Tab = createBottomTabNavigator();

const MainTabNavigator = () => {
  return (
    <Tab.Navigator>
      <Tab.Screen
        name="Main.Tab"
        component={MainNavigator}
        options={({ route }) => ({
          headerShown: false,
          tabBarIcon: (tabInfo) => {
            return <Feather name="home" color={tabInfo.color} size={25} />;
          },
          tabBarShowLabel: false,
        })}
      />
      <Tab.Screen
        name="Schedule.Tab"
        component={ScheduleNavigator}
        options={({ route }) => ({
          headerShown: false,
          tabBarIcon: (tabInfo) => {
            return <Feather name="calendar" color={tabInfo.color} size={25} />;
          },
          tabBarShowLabel: false,
        })}
      />

      <Tab.Screen
        name="Cafe.Tab"
        component={CafeNavigator}
        options={({ route }) => ({
          headerShown: false,
          tabBarIcon: (tabInfo) => {
            return <Feather name="coffee" color={tabInfo.color} size={25} />;
          },
          tabBarShowLabel: false,
        })}
      />
      <Tab.Screen
        name="Favourite.Tab"
        component={FavouriteNavigator}
        options={({ route }) => ({
          headerShown: false,
          tabBarIcon: (tabInfo) => {
            return <Feather name="heart" color={tabInfo.color} size={25} />;
          },
          tabBarShowLabel: false,
        })}
      />
      <Tab.Screen
        name="Member.Tab"
        component={MemberComingSoonScreen}
        options={({ route }) => ({
          // headerShown: false,
          tabBarIcon: (tabInfo) => {
            return <Feather name="user" color={tabInfo.color} size={25} />;
          },
          tabBarShowLabel: false,
          title: "用戶協議",
          // backgroundColor: colors.main,
          // headerBackground: Colors.main,

          headerStyle: {
            // backgroundColor: "rgba(255,255,255,0.5)",
            backgroundColor: Colors.main,
          },
        })}
      />
    </Tab.Navigator>
  );
};

export default MainTabNavigator;
