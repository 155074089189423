import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import ScheduleScreen from "../screens/Schedules/ScheduleScreen";
import MainNavigator from "./MainNavigator";
import MainTabNavigator from "./MainTabNavigator";

const RootNavigator = () => {
  return (
    <NavigationContainer>
      <MainTabNavigator />
    </NavigationContainer>
  );
};

export default RootNavigator;
