import { HeaderBackButton } from "@react-navigation/elements";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Button, TouchableOpacity } from "react-native";
import Colors from "../constants/Colors";
import AddMyFavouritScreen from "../screens/Favourites/AddMyFavouriteScreen";
import MyFavouritScreen from "../screens/Favourites/MyFavouriteScreen";
import { Feather } from "@expo/vector-icons";

const Stack = createNativeStackNavigator();

const FavouriteNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.main,
        },
      }}
      initialRouteName="Favourite.Main"
    >
      <Stack.Screen
        name="Favourite.Main"
        component={MyFavouritScreen}
        options={({ navigation, route }) => ({
          // title: "香港女僕Cafe資訊",
          title: "我的最愛",
          headerTintColor: "#222",
          // headerRight: () => {
          //   return (
          //     <TouchableOpacity
          //       onPress={() => {
          //         navigation.navigate("Favourite.Add");
          //       }}
          //     >
          //       <Feather name="plus" color={"#222"} size={28} />
          //     </TouchableOpacity>
          //   );
          // },
        })}
      />
      <Stack.Screen
        name="Favourite.Add"
        component={AddMyFavouritScreen}
        options={({ navigation, route }) => ({
          title: "Edit 我的最愛",
        })}
      />
    </Stack.Navigator>
  );
};

export default FavouriteNavigator;
