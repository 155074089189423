import MyFavouriteStore from "./MyFavourite";

const providers = [
  MyFavouriteStore.Provider,
  // LocaleStore.Provider,
  // AuthStatusStore.Provider,
  // DeviceStatusStore.Provider,
  // BleManagerStore.Provider,
  // LocaleStore.Provider,
];

// providers Array
// [a, b, c, d,]

// Will become
// <d>
//   <c>
//     <b>
//       <a>

export default providers;
