import { useEffect, useState } from "react";
import {
  Dimensions,
  Linking,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import Markdown from "react-native-markdown-display";
import { Feather } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
import colors from "../../constants/Colors";

const MetaInfoContainer = ({ icon, children }) => {
  const windowWidth = Dimensions.get("window").width;

  return (
    <View
      style={{
        flexDirection: "row",
        // backgroundColor: "lightgreen",
        marginVertical: 5,
      }}
    >
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          maxWidth: windowWidth / 5,
        }}
      >
        {/* {icon ? (
          <Text
            style={{
              color: "#5e5e5e",
              fontWeight: "bold",
              marginRight: 5,
            }}
          >
            {metaArr.title}:
          </Text>
        ) : null} */}
        <Text
          style={{
            color: "#5e5e5e",
            fontWeight: "bold",
            marginRight: 5,
          }}
        >
          {icon}
        </Text>
      </View>
      <View
        style={{
          // backgroundColor: "lightgreen",
          flex: 1,
          justifyContent: "center",
        }}
      >
        {/* <Markdown style={mdStyles}>{metaArr.content}</Markdown> */}
        {children}
      </View>
    </View>
  );
};

const MetaInfoSection = ({ info }) => {
  const windowWidth = Dimensions.get("window").width;

  const [metasArr, setMetasArr] = useState();
  const [birthday, setBirthday] = useState();

  const { facebook, instagram } = info;

  useEffect(() => {
    // let metaArr = JSON.parse(meta);
    if (info.cafe_meta) {
      // setMetasArr(JSON.parse(info.cafe_meta));
      setMetasArr(info.cafe_meta);
    }
    if (info.maid_meta) {
      setMetasArr(info.maid_meta);
    }

    if (info.birthday) {
      // let bDay = JSON.parse(info.birthday);
      let bDay = info.birthday;

      let bDayString = "";

      if (bDay.year) bDayString = bDayString + bDay.year + "年";

      // if (bDay.year && bDay.month) bDayString = bDayString + " - ";

      if (bDay.month) bDayString = bDayString + bDay.month + "月";

      // if (bDay.month && bDay.day) bDayString = bDayString + " - ";
      if (bDay.day) bDayString = bDayString + bDay.day + "日";

      // console.log("BDAY STRING", bDayString, bDayString.length);

      setBirthday(bDayString.length > 0 ? bDayString : null);
    }
  }, [info]);

  return (
    <View style={{ marginBottom: 20 }}>
      {birthday ? (
        <MetaInfoContainer icon={<Text>生日</Text>}>
          <Text>{birthday}</Text>
        </MetaInfoContainer>
      ) : null}

      {info.address ? (
        <MetaInfoContainer icon={<Feather name="map" size={24} />}>
          {info.address?.google_map ? (
            <TouchableOpacity
              style={
                {
                  // backgroundColor: "red",
                }
              }
              onPress={() => {
                // const googleGeo = JSON.parse(info.address?.google_map);
                const googleGeo = info.address?.google_map;
                const scheme = Platform.select({
                  ios: "maps:0,0?q=",
                  android: "geo:0,0?q=",
                  web: "https://maps.google.com/?q=",
                });

                const latLng = `${googleGeo.latlng.lat},${googleGeo.latlng.lng}`;

                // console.log("ADDRESS GEO", googleGeo);
                Linking.openURL(scheme + latLng);
                // Linking.openURL(scheme + "DreamyHome");
              }}
            >
              <Text style={{ color: "blue" }}>
                {info.address?.display_address}
              </Text>
            </TouchableOpacity>
          ) : (
            <Text style={{ color: "#222" }}>
              {info.address?.display_address}
            </Text>
          )}
        </MetaInfoContainer>
      ) : null}

      {info.phone ? (
        <MetaInfoContainer icon={<Feather name="phone" size={24} />}>
          <TouchableOpacity
            style={
              {
                // backgroundColor: "red",
              }
            }
            onPress={() => {
              console.log("Pressed Tel");
              Linking.openURL(`tel:${info.phone}`);
            }}
          >
            <Text style={{ color: "blue" }}>{info.phone}</Text>
          </TouchableOpacity>
        </MetaInfoContainer>
      ) : null}

      {facebook ? (
        <MetaInfoContainer icon={<Feather name="facebook" size={24} />}>
          <TouchableOpacity
            style={
              {
                // backgroundColor: "red",
              }
            }
            onPress={() => {
              Linking.openURL(`${facebook}`);
            }}
          >
            <Text style={{ color: "blue" }}>
              {info.facebook_text ? info.facebook_text : "Facebook"}
            </Text>
          </TouchableOpacity>
        </MetaInfoContainer>
      ) : null}

      {instagram ? (
        <MetaInfoContainer icon={<Feather name="instagram" size={24} />}>
          <TouchableOpacity
            style={
              {
                // backgroundColor: "red",
              }
            }
            onPress={() => {
              Linking.openURL(`https://www.instagram.com/${instagram}`);
            }}
          >
            <Text style={{ color: "blue" }}>{`@${instagram}`}</Text>
          </TouchableOpacity>
        </MetaInfoContainer>
      ) : null}

      {metasArr
        ? metasArr.map((metaArr, index) => {
            return (
              <View
                style={{
                  flexDirection: "row",
                  // backgroundColor: "lightgreen",
                }}
                key={index}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: windowWidth / 5,
                  }}
                >
                  {metaArr.title ? (
                    <Text
                      style={{
                        color: "#5e5e5e",
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      {metaArr.title}:
                    </Text>
                  ) : null}
                </View>
                <View
                  style={{
                    // backgroundColor: "lightgreen",
                    flex: 1,
                  }}
                >
                  <Markdown style={mdStyles}>{metaArr.content}</Markdown>
                </View>
              </View>
            );
          })
        : null}
    </View>
  );
};

const mdStyles = StyleSheet.create({
  paragraph: {
    marginTop: 5,
    marginBottom: 5,
  },
});
export default MetaInfoSection;
