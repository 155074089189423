import providers from './providers';

const ProviderComposer = (props) =>
  props.providers.reduce(
    (children, Parent) => <Parent>{children}</Parent>,
    props.children
  );

// props.providers.reduceRight(
//   (children, Parent) => <Parent>{children}</Parent>,
//   props.children
// );

const CombinedProvider = (props) => {
  return (
    <ProviderComposer providers={providers}>{props.children}</ProviderComposer>
  );
};

export default CombinedProvider;
