import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import RootNavigator from "./src/navigators/RootNavigator";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CombinedProvider from "./src/stores/CombinedProvider";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CombinedProvider>
        <RootNavigator />
      </CombinedProvider>
    </QueryClientProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
