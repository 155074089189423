import { StatusBar, View } from "react-native";

const MainLayout = ({ children }) => {
  return (
    <View style={{ flex: 1 }}>
      <StatusBar />
      {children}
    </View>
  );
};

export default MainLayout;
