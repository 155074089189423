import { TabRouter } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import colors from "../constants/Colors";
import CafeProfileScreen from "../screens/CafeProfileScreen";
import MaidProfileScreen from "../screens/MaidProfileScreen";
import ScheduleScreen from "../screens/Schedules/ScheduleScreen";
import TestScreen from "../screens/TestScreen";

const Stack = createNativeStackNavigator();

const ScheduleNavigator = () => {
  return (
    <Stack.Navigator>
      {/* <Stack.Screen name="Schedule.Test" component={TestScreen} /> */}
      <Stack.Screen
        name="Schedule.Main"
        component={ScheduleScreen}
        options={{
          // title: "香港女僕Cafe資訊",
          title: "當值資訊",

          headerTitleStyle: {
            textAlign: "center",
            fontSize: 17,
          },

          // headerTintColor: "#505050",
          // headerTintColor: "#fff",
          headerTintColor: "#222",

          headerTitleAlign: "center",
          headerStyle: {
            // backgroundColor: "rgba(255,255,255,0.5)",
            backgroundColor: colors.main,
          },
        }}
      />
      {/* <Stack.Screen
        name="Schedule.Cafe"
        component={CafeProfileScreen}
        options={({ route }) => ({
          headerTransparent: true,
          title: route.params.title,

          headerTitleStyle: {
            textAlign: "center",
            fontSize: 17,
          },

          headerTintColor: "#fff",
          // headerTintColor: "#505050",

          headerTitleAlign: "center",
          headerStyle: {
            // backgroundColor: "rgba(255,255,255,0.5)",
            backgroundColor: "rgba(0,0,0,0.4)",
            backgroundColor: colors.main + "99",
          },
        })}
      /> */}
      {/* <Stack.Screen
        name="Schedule.Maid"
        component={MaidProfileScreen}
        options={({ route }) => ({
          // title: "Profile",
          title: route.params.title ? route.params.title : "Profile",

          headerTransparent: true,

          headerTitleStyle: {
            textAlign: "center",
            fontSize: 17,
          },

          headerTintColor: "#fff",
          headerTitleAlign: "center",
          headerStyle: {
            // backgroundColor: "rgba(255,255,255,0.5)",
            // backgroundColor: "rgba(0,0,0,0.4)",
            backgroundColor: colors.main + "99",
          },
        })}
      /> */}
    </Stack.Navigator>
  );
};

export default ScheduleNavigator;
