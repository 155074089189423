import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Colors from "../constants/Colors";

import CafeProfileScreen from "../screens/CafeProfileScreen";
import CafeListScreen from "../screens/Cafes/CafeListScreen";
import MaidProfileScreen from "../screens/MaidProfileScreen";

import { HeaderBackButton } from "@react-navigation/elements";
import colors from "../constants/Colors";
import { StackActions } from "@react-navigation/native";

const Stack = createNativeStackNavigator();

const CafeNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="Cafe.List">
      <Stack.Screen
        name="Cafe.List"
        component={CafeListScreen}
        options={({ route }) => ({
          headerShown: true,
          title: "香港女僕Cafe",

          headerTitleStyle: {
            textAlign: "center",
            fontSize: 17,
          },
          headerTintColor: "#222",
          headerTitleAlign: "center",
          headerStyle: {
            // backgroundColor: "rgba(255,255,255,0.5)",
            backgroundColor: colors.main,
          },
          headerBackVisible: false,
        })}
      />
      <Stack.Screen
        name="Cafe.CafeDetail"
        component={CafeProfileScreen}
        options={({ route, navigation }) => ({
          headerTransparent: true,
          // title: route.params.title,
          title: "Cafe",

          headerTitleStyle: {
            textAlign: "center",
            fontSize: 17,

            color: "transparent",
          },

          // headerTintColor: "#fff",
          // headerTintColor: "#505050",
          headerTintColor: "#222",

          headerTitleAlign: "center",
          headerStyle: {
            // backgroundColor: "rgba(255,255,255,0.5)",
            // backgroundColor: "rgba(0,0,0,0.4)",
            backgroundColor: Colors.main + "99",
          },
          headerBackVisible: false,
          headerLeft: () => {
            return (
              <HeaderBackButton
                onPress={() => {
                  // navigation.reset("Cafe.Tab", { screen: "Cafe.List" });
                  navigation.replace("Cafe.List");
                }}
                // tintColor={"#fff"}
                tintColor={"#222"}
              />
            );
          },
        })}
      />
      <Stack.Screen
        name="Cafe.MaidDetail"
        component={MaidProfileScreen}
        options={({ route, navigation }) => ({
          // title: "Profile",
          // title: route.params.title ? route.params.title : "Profile",
          title: "Maid",

          headerTransparent: true,

          headerTitleStyle: {
            textAlign: "center",
            fontSize: 17,
            color: "transparent",
          },

          // headerTintColor: "#fff",
          // headerTintColor: "#505050",
          headerTintColor: "#222",

          headerTitleAlign: "center",
          headerStyle: {
            // backgroundColor: "rgba(255,255,255,0.5)",
            // backgroundColor: "rgba(0,0,0,0.4)",
            backgroundColor: Colors.main + "99",
          },
          headerBackVisible: false,
          headerLeft: (props) => {
            console.log("Route?", route.params.cafeId, route.params.cafeTitle);
            return (
              <HeaderBackButton
                onPress={() => {
                  // navigation.replace("Cafe.List");

                  navigation.dispatch(
                    StackActions.replace("Cafe.CafeDetail", {
                      cafeId: route.params.cafeId,
                      title: route.params.cafeTitle,
                    })
                  );
                }}
                tintColor={"#222"}
              />
            );
          },
        })}
      />
    </Stack.Navigator>
  );
};

export default CafeNavigator;
