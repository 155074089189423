export const WEEKDAYS_REF_TC = [
  "星期日",
  "星期一",
  "星期二",
  "星期三",
  "星期四",
  "星期五",
  "星期六",
];

export const WEEKDAYS_REF_EN = [
  "sun",
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
];

export const OPENING_HOURS_WEEKDAYS_REF_TC = [
  "星期日",
  "星期一",
  "星期二",
  "星期三",
  "星期四",
  "星期五",
  "星期六",
  "公眾假期",
];

export const OPENING_HOURS_WEEKDAYS_REF_EN = [
  "sun",
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
  "public holiday",
];
