import { useHeaderHeight } from "@react-navigation/elements";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Text, View, StyleSheet, ScrollView } from "react-native";
import Markdown from "react-native-markdown-display";
import { API_URL } from "../../constants/api_url";
import colors from "../../constants/Colors";
import MyFavouriteStore from "../../stores/MyFavourite";

const MemberComingSoonScreen = () => {
  const headerHeight = useHeaderHeight();
  const [isRefetchingUserAgreement, setIsRefetchingUserAgreement] =
    useState(false);

  // const { state: MyFavouritedState, dispatch: MyFavouritedDispatch } =
  //   useContext(MyFavouriteStore.Context);

  const refetchUserAgreement = async () => {
    setIsRefetchingUserAgreement(true);
    try {
      await refetch();
    } catch (err) {
    } finally {
      setIsRefetchingUserAgreement(false);
    }
  };

  const fetchUserAgreement = async (id) => {
    const fetchLink = API_URL + "/api/v1/settings/user-agreement";
    console.log("FetchLink: ", fetchLink);
    try {
      const response = await fetch(fetchLink);
      const json = await response.json();
      console.log(json.data);
      return json.data;
    } catch (error) {
      console.error("FETCH ERROR: ", error);
      throw new Error(error);
    } finally {
    }
  };

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["userAgreement"],
    queryFn: fetchUserAgreement,
  });

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.main,
        // marginTop: headerHeight,
        paddingHorizontal: 10,
        paddingBottom: 30,
      }}
    >
      <ScrollView style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: colors.dark,
          }}
        >
          {/* <Text
        style={{
          color: "#333",
          fontSize: 24,
          fontWeight: "600",
        }}
      >
        全新會員系統
      </Text>
      <Text
        style={{
          color: "#333",
          fontSize: 24,
          fontWeight: "600",
        }}
      >
        快將更新！敬請期待！
      </Text> */}

          {!isLoading && !isError ? (
            <Markdown style={mdStyles}>{data.user_agreement}</Markdown>
          ) : null}
        </View>
      </ScrollView>
    </View>
  );
};

const mdStyles = StyleSheet.create({
  paragraph: {
    marginTop: 5,
    marginBottom: 5,
  },
  h1: {
    fontSize: 24,
    fontWeight: "600",
  },
});

export default MemberComingSoonScreen;
