import AsyncStorage from "@react-native-async-storage/async-storage";
import { createContext, useEffect, useReducer, useState } from "react";
import { getObjectData } from "../../functions/functions";
import reducer, { initState } from "./reducer";

const Context = createContext({});

const Provider = (props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  // const [currentLocale, setCurrentLocale] = useState(i18n.locale);

  // const
  useEffect(() => {
    const retrieveMaids = async () => {
      let favMaids = await getObjectData("@my_favourite");

      console.log("===== =====");
      console.log("My Favourite Store");
      console.log("Stored My Favourite in Async: ", favMaids);
      console.log("===== =====");
      // setMyFavouriteMai(favMaids);
      if (favMaids) {
        dispatch({ type: "INIT_FAV", payload: { savedFav: favMaids } });
      }
      return favMaids;
    };
    retrieveMaids();
  }, []);

  useEffect(() => {
    console.log("State Changed: My Fav:", state.myFav);
  }, [state.myFav]);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

const MyFavouriteStore = { Context, Provider };

export default MyFavouriteStore;
