import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  ActivityIndicator,
  View,
  FlatList,
  RefreshControl,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  Modal,
  Alert,
  Pressable,
  StyleSheet,
} from "react-native";
import Markdown from "react-native-markdown-display";
import { API_URL } from "../../constants/api_url";
import { Feather } from "@expo/vector-icons";

const EventPostScreen = ({ navigation }) => {
  const windowWidth = Dimensions.get("window").width;
  const [modalVisible, setModalVisible] = useState(false);

  const [modalContent, setModalContent] = useState(null);

  const [isRefetchingPosts, setIsRefetchingPosts] = useState(false);

  const refetchPosts = async () => {
    setIsRefetchingPosts(true);
    try {
      await refetch();
    } catch (err) {
    } finally {
      setIsRefetchingPosts(false);
    }
  };

  const fetchPosts = async () => {
    const fetchLink = API_URL + "/api/v1/posts";
    console.log("FetchLink: ", fetchLink);
    try {
      const response = await fetch(fetchLink);
      const json = await response.json();
      // if (json) {
      //   let result = checkMaidExist(json.data)
      // }
      // console.log("Data retrieved:", json.data);
      return json.data;
    } catch (error) {
      console.error("Posts FETCH ERROR: ", error);
      throw new Error(error);
    } finally {
    }
  };

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ["posts"],
    queryFn: fetchPosts,
  });

  const renderPost = ({ item }) => {
    const { image, cafe, post_meta } = item;

    // console.log(item);

    return (
      <View
        style={[
          {
            marginBottom: 20,
            backgroundColor: "#fff",
            borderRadius: 10,
            minHeight: windowWidth + windowWidth / 7,
          },
          {
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.2,
            shadowRadius: 1.41,

            elevation: 2,
          },
        ]}
      >
        <View
          style={{
            width: windowWidth - 20,
            minHeight: windowWidth - 20,
            padding: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("Cafe.Tab", {
                screen: "Cafe.CafeDetail",
                params: {
                  cafeId: cafe.id,
                  title: cafe.title,
                },
              });
            }}
          >
            <View
              style={{
                height: windowWidth / 7,
                marginBottom: 10,
                flexDirection: "row",
              }}
            >
              <View>
                <View
                  style={[
                    {
                      width: windowWidth / 7,
                      height: windowWidth / 7,
                      // backgroundColor: "pink",
                      borderRadius: windowWidth / 7 / 2,

                      backgroundColor: "#a0a0a0",
                      overflow: "hidden",
                    },
                    {
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      shadowOpacity: 0.2,
                      shadowRadius: 1.41,
                      elevation: 2,
                    },
                  ]}
                >
                  {cafe.logo ? (
                    <Image
                      source={{ uri: API_URL + "/" + cafe.logo }}
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#a0a0a0",
                      }}
                    />
                  ) : null}
                </View>
              </View>
              <View style={{ justifyContent: "center", marginLeft: 14 }}>
                <Text
                  style={{ fontSize: 18, marginBottom: 10, fontWeight: "600" }}
                >
                  {cafe.title}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
          <View
            style={{
              width: windowWidth - 20 - 20,
              height: windowWidth - 20 - 20,
              // backgroundColor: "blue",
            }}
          >
            {image ? (
              <TouchableOpacity
                style={{ width: "100%", height: "100%" }}
                onPress={() => {
                  setModalContent(null);
                  setModalContent(post_meta);
                  setModalVisible(true);
                }}
              >
                <Image
                  source={{ uri: API_URL + "/" + image }}
                  style={{
                    width: "100%",
                    height: "100%",
                    flex: 1,
                  }}
                  resizeMode="contain"
                  // resizeMode=""
                />
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        // backgroundColor: "pink",
        backgroundColor: "white",
      }}
    >
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <TouchableOpacity
          style={styles.centeredView}
          onPress={() => {
            setModalVisible(!modalVisible);
          }}
        >
          {/* <View style={styles.centeredView}> */}
          <View style={styles.modalView}>
            {modalContent
              ? modalContent.map((meta, index) => {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        // backgroundColor: "lightgreen",
                      }}
                      key={index}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          maxWidth: windowWidth / 5,
                        }}
                      >
                        {meta.title ? (
                          <Text
                            style={{
                              color: "#5e5e5e",
                              fontWeight: "bold",
                              marginRight: 5,
                            }}
                          >
                            {meta.title}:
                          </Text>
                        ) : null}
                      </View>
                      <View
                        style={{
                          // backgroundColor: "lightgreen",
                          flex: 1,
                        }}
                      >
                        <Markdown style={mdStyles}>{meta.content}</Markdown>
                      </View>
                    </View>
                  );
                })
              : null}
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => setModalVisible(!modalVisible)}
            >
              {/* <Text style={styles.textStyle}>Hide Modal</Text> */}
              {/* <Text></Text> */}
              <Feather name="x" size={24} color="#fff" />
            </Pressable>
          </View>
          {/* </View> */}
        </TouchableOpacity>
      </Modal>
      <View
        style={{
          backgroundColor: "#f0f0f0",
          flex: 1,
        }}
      >
        {isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size={"large"} />
          </View>
        ) : null}

        {isError ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Error Occurs</Text>
          </View>
        ) : null}

        {!isLoading && !isError ? (
          <>
            <FlatList
              data={data}
              renderItem={renderPost}
              keyExtractor={(item) => "cafe_" + item?.id}
              contentContainerStyle={{
                paddingVertical: 10,
                paddingHorizontal: 10,
                // backgroundColor: "white",
                // backgroundColor: "lightgreen",
                // flex: 1,
                minHeight: "100%",
              }}
              refreshControl={
                <RefreshControl
                  refreshing={isRefetchingPosts}
                  onRefresh={refetchPosts}
                />
              }
            />
          </>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});

const mdStyles = StyleSheet.create({
  paragraph: {
    marginTop: 5,
    marginBottom: 5,
  },
});
export default EventPostScreen;
