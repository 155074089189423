import { memo, useContext, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import MyFavouriteStore from "../../stores/MyFavourite";
import FavMaidButton from "./FavMaidButton";

const FavMaidsCard = ({ cafeId, maidsData }) => {
  const [maids, setMaids] = useState(maidsData);
  // console.log("Fav Maids Card Triggered", cafeId);

  useEffect(() => {
    // console.log("Fav Maids Card maidsData changed", cafeId);
  }, [maidsData]);

  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {maids?.map((maid) => {
        // console.log("Called Mapping to rerender");
        return (
          <FavMaidButton
            cafeId={cafeId}
            maidData={maid}
            key={"cafe_" + cafeId + "_" + maid.id}
          />
        );
      })}
    </View>
  );
};

export default memo(FavMaidsCard);
