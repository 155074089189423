import AsyncStorage from "@react-native-async-storage/async-storage";

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function shuffleArray(arr) {
  console.log(
    "it is array?",
    typeof arr,
    typeof arr == "array",
    typeof arr === "object"
  );
  if (typeof arr === "array" || typeof arr === "object") {
    arr.sort(() => Math.random() - 0.5);
  }
}

export const storeObjectData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    console.log("Store to Async Error", e);
  } finally {
    console.log("Store to Async Finish", value);
  }
};

export const getObjectData = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log("Get from  Async Error", e);
  }
};
