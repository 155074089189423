import { TabRouter } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import colors from "../constants/Colors";
import CafeProfileScreen from "../screens/CafeProfileScreen";
import MaidProfileScreen from "../screens/MaidProfileScreen";
import EventPostScreen from "../screens/Main/EventPostScreen";
import ScheduleScreen from "../screens/Schedules/ScheduleScreen";
import TestScreen from "../screens/TestScreen";

const Stack = createNativeStackNavigator();

const MainNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Main.Main"
        component={EventPostScreen}
        options={{
          title: "主頁",

          headerTitleStyle: {
            // textAlign: "center",
            fontSize: 17,
          },
          headerTitleAlign: "center",
          headerStyle: {
            backgroundColor: colors.main,
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default MainNavigator;
