import { useState } from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';

const Post = ({ post, onCafePress, headerShown = true }) => {
  const imageWidth = Math.floor(useWindowDimensions().width / 3);
  const eventTitleSectionWidth = Math.floor(useWindowDimensions().width / 5);
  const [liked, setLiked] = useState(false);
  const [join, setJoin] = useState(false);

  const handleLiked = () => {
    setLiked((prev) => !prev);
  };

  // if (post.event_type == 'event') {
  //   console.log('Post', post);
  // }
  return (
    <View style={styles.container}>
      {headerShown ? (
        <View style={styles.infoContainer}>
          <TouchableOpacity onPress={onCafePress}>
            <View
              style={{
                height: 40,
                width: 40,
                backgroundColor: 'pink',
                borderRadius: 10,
                marginRight: 5,
              }}
            ></View>
          </TouchableOpacity>
          <View
            style={{
              paddingBottom: 3,
            }}
          >
            <TouchableOpacity
              onPress={onCafePress}
              style={{
                // backgroundColor: 'blue',
                paddingHorizontal: 5,
                paddingVertical: 3,
              }}
            >
              <Text style={{ color: '#505050' }}>{post.cafe?.title}</Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <></>
      )}
      <View>
        <View style={[styles.mediaContainer]}>
          <Image
            style={[
              {
                resizeMode: 'contain',
                // width: imageWidth,
                width: '100%',
                aspectRatio: 1 / 1,
                // flex: 1,
                // height: imageWidth,
              },
              // styles.mediaImage,
            ]}
            source={{ uri: 'https://via.placeholder.com/1000' }}
          />
        </View>
        <View style={styles.mediaInfoContainer}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity onPress={handleLiked} style={{ padding: 2 }}>
              {liked ? (
                <Ionicons
                  name="heart-sharp"
                  size={20}
                  color="#505050"
                  solid
                  style={{
                    fontWeight: 'bold',
                  }}
                />
              ) : (
                <Ionicons
                  name="heart-outline"
                  size={20}
                  color="#505050"
                  solid
                  style={{
                    fontWeight: 'bold',
                  }}
                />
              )}
            </TouchableOpacity>
            <Text
              style={{
                color: '#505050',
                fontWeight: 'bold',
                // textAlignVertical: 'center',
                marginLeft: 4,
              }}
            >
              Likes
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <Text style={{ color: '#505050' }}>{post.content}</Text>

        {post.post_type == 'event' ? (
          <View style={{ marginTop: 10, marginBottom: 10 }}>
            <View
              style={{
                // marginBottom: 10,
                paddingVertical: 5,
                backgroundColor: 'white',
                // borderRadius: 10,
                borderTopStartRadius: 10,
                borderTopEndRadius: 10,
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowOpacity: 0.22,
                shadowRadius: 2.22,

                elevation: 3,
                zIndex: 2,
              }}
            >
              <Text
                style={{
                  color: '#505050',
                  fontSize: 17,
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                活動詳情
              </Text>
            </View>
            <View
              style={{
                paddingVertical: 10,
                paddingHorizontal: 15,
                backgroundColor: 'white',
                // borderRadius: 10,
                borderBottomStartRadius: 10,
                borderBottomEndRadius: 10,
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowOpacity: 0.22,
                shadowRadius: 2.22,

                elevation: 3,
              }}
            >
              <View
                style={{
                  borderBottomColor: '#c0c0c0',
                  borderBottomWidth: 1,
                  paddingBottom: 5,
                }}
              >
                <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                  <View
                    style={{
                      // width: eventTitleSectionWidth,
                      width: '30%',
                      // backgroundColor: 'blue',
                    }}
                  >
                    <Text
                      style={{
                        color: '#505050',
                        fontSize: 15,
                        fontWeight: '600',
                      }}
                    >
                      Title:
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        color: '#505050',
                        // fontSize: 15,

                        // fontWeight: '600',
                      }}
                    >
                      {post.event?.title}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                  <View
                    style={{
                      // width: eventTitleSectionWidth,
                      width: '30%',
                      // backgroundColor: 'blue',
                    }}
                  >
                    <Text
                      style={{
                        color: '#505050',
                        fontSize: 14,
                        fontWeight: '600',
                      }}
                    >
                      Description:
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        color: '#505050',
                        // fontSize: 15,

                        // fontWeight: '600',
                      }}
                    >
                      {post.event?.description}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                  <View
                    style={{
                      // width: eventTitleSectionWidth,
                      width: '30%',
                      // backgroundColor: 'blue',
                    }}
                  >
                    <Text
                      style={{
                        color: '#505050',
                        fontSize: 15,
                        fontWeight: '600',
                      }}
                    >
                      Event Date:
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        color: '#505050',
                        // fontSize: 15,

                        // fontWeight: '600',
                      }}
                    >
                      {post.event?.event_date}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginTop: 15,
                  marginBottom: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setJoin((prev) => !prev);
                  }}
                  style={{
                    // borderWidth: 1,
                    borderRadius: 8,
                    // borderColor: '#c0c0c0',

                    paddingVertical: 8,
                    backgroundColor: !join ? '#f0dcde' : '#a6404a',
                    paddingHorizontal: 10,
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      color: !join ? '#353535' : '#ffffff',
                      textAlign: 'center',
                      fontSize: 16,
                    }}
                  >
                    {!join ? '參加！' : '已參加！'}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : (
          // <Text style={{ color: '#505050' }}>N/A</Text>
          <></>
        )}
        <Text style={{ color: '#505050', marginTop: 5, fontWeight: '500' }}>
          {post.public_date}
        </Text>
      </View>
    </View>
  );
};

export default Post;

const styles = StyleSheet.create({
  container: {
    minWidth: '100%',
    marginBottom: 15,
    // paddingHorizontal: 10,
    paddingVertical: 10,
  },
  infoContainer: {
    flexDirection: 'row',
    paddingHorizontal: 10,

    alignItems: 'center',
    marginBottom: 4,
  },
  mediaContainer: {
    // minWidth: '100%',
    // backgroundColor: '#E9D2FF',
    marginVertical: 2,
    marginTop: 2,
    marginBottom: 3,
  },
  mediaImage: {},
  mediaInfoContainer: {
    paddingHorizontal: 15,
  },
  contentContainer: {
    paddingHorizontal: 15,
  },
});
