import { Text, View } from "react-native";
import Colors from "../../constants/Colors";
import { shuffleArray } from "../../functions/functions";
import WeekdayCard from "../Schedules/WeekdayCard";
import MaidButton from "./MaidButton";

const OpeningHoursText = ({ openingHours }) => {
  const { start, end } = openingHours;
  const startText = () => {
    if (openingHours.start) {
      return <Text>{openingHours.start}</Text>;
    }
    return;
  };
  function connectionText() {
    if (openingHours.start && openingHours.end) {
      return <Text style={{ marginHorizontal: 3 }}>~</Text>;
    }
    return;
  }

  function endText() {
    if (openingHours.end) {
      return <Text>{openingHours.end}</Text>;
    }
    return;
  }

  return (
    <Text style={{ color: "black" }}>
      {start ? <Text>{start}</Text> : null}
      {start && end ? <Text style={{}}> ~ </Text> : null}
      {end ? <Text>{end}</Text> : null}
    </Text>
  );
};

const CafeSchedules = ({ schedules }) => {
  return (
    <View>
      {schedules.map((schedule, index) => {
        let curDate = new Date(schedule.schedule_date);
        // let openingHours = JSON.parse(schedule.opening_hours);
        let openingHours = schedule.opening_hours;

        let displayMaids = [...schedule.maids];
        shuffleArray(displayMaids);

        return (
          <View
            key={index + "_" + schedule.schedule_date}
            style={{
              flexDirection: "row",
              marginBottom: 10,
            }}
          >
            <View>
              <WeekdayCard date={curDate} />
            </View>
            <View
              style={{
                flex: 1,
                backgroundColor: "#fafafa",
                paddingHorizontal: 5,
                paddingVertical: 5,
              }}
            >
              <View style={{ marginBottom: 5 }}>
                {schedule.event_title ? (
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#505050",
                      fontWeight: "500",
                      paddingRight: 10,
                      color: Colors.dark,
                    }}
                  >
                    {schedule.event_title}
                  </Text>
                ) : // <OpeningHoursText
                null}
                {openingHours ? (
                  <OpeningHoursText openingHours={openingHours} />
                ) : null}
              </View>
              <View
                style={{
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {displayMaids?.map((maid) => {
                  return (
                    <View
                      style={{ marginBottom: 10, marginRight: 5 }}
                      key={maid.id}
                    >
                      <MaidButton maid={maid} />
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default CafeSchedules;
