import { useFocusEffect } from "@react-navigation/native";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  RefreshControl,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { API_URL } from "../../constants/api_url";
import colors from "../../constants/Colors";
import { getObjectData, shuffleArray } from "../../functions/functions";
import MyFavouriteStore from "../../stores/MyFavourite";
import { Feather } from "@expo/vector-icons";
import DisplayFavCafeCard from "../../components/MyFavourite/DisplayFavCafeCard";

function checkMaidExist(targetArr, OriArr) {
  let target = [...targetArr];
  let original = [...OriArr];

  original.forEach((favCafe) => {});

  let cafeIndex = target.findIndex((cafe) => cafe.id == cafeId);
  // Cafe Exist
  if (cafeIndex >= 0) {
    // Check if Maid in list
    let maidIndex = target[cafeIndex].maids.findIndex(
      (maidI) => maidI == maidId
    );
    console.log("Cafe Result :", target[cafeIndex]);
    // Maid Exist in array
    if (maidIndex >= 0) {
      // return true;
      // Remove pressed Maid from list
      target[cafeIndex].maids.splice(maidIndex, 1);
    } else {
      // Maid Not Exist in array
      // return false;
      target[cafeIndex].maids.push(maidId);
    }
  } else {
    // Cafe Not Exist
    // return false;
    // Create Cafe object with the Maid record
    target.push({
      id: cafeId,
      maids: [maidId],
    });
  }
  return target;
}

const MyFavouritScreen = ({ navigation, route }) => {
  const [isRefetchingFavourite, setIsRefetchingFavourite] = useState(false);
  const [myFavouriteMaidsDisplay, setMyFavouriteMaidsDisplay] = useState();

  const { state: MyFavouriteState, dispatch: MyFavouriteDispatch } = useContext(
    MyFavouriteStore.Context
  );

  useFocusEffect(() => {
    const retrieveMaids = async () => {
      let favMaids = await getObjectData("@my_favourite");

      console.log("===== =====");
      console.log("My Favourite Store");
      console.log("Stored My Favourite in Async: ", favMaids);
      console.log("===== =====");
      // setMyFavouriteMai(favMaids);
      if (favMaids) {
        // dispatch({ type: "INIT_FAV", payload: { savedFav: favMaids } });
      }
      return favMaids;
    };
    retrieveMaids();
  });

  // Navigation Button
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableOpacity
            style={{
              marginRight: 10,
            }}
            onPress={() => {
              MyFavouriteDispatch({ type: "COPY_FOR_WORKING_ON" });
              navigation.navigate("Favourite.Add");
            }}
          >
            <Feather name="plus" color={"#222"} size={28} />
          </TouchableOpacity>
        );
      },
    });
  }, [navigation]);

  const refetchFavourite = async () => {
    setIsRefetchingFavourite(true);
    try {
      await refetch();
    } catch (err) {
    } finally {
      setIsRefetchingFavourite(false);
    }
  };

  const fetchFavourite = async () => {
    const fetchLink = API_URL + "/api/v1/my-favourite";
    console.log("FetchLink: ", fetchLink);
    try {
      const response = await fetch(fetchLink);
      const json = await response.json();
      // if (json) {
      //   let result = checkMaidExist(json.data)
      // }
      // console.log("Data retrieved:", json.data);
      return json.data;
    } catch (error) {
      console.error("Cafe List FETCH ERROR: ", error);
      throw new Error(error);
    } finally {
    }
  };

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ["favourites"],
    queryFn: fetchFavourite,
  });

  useFocusEffect(
    useCallback(() => {
      let result = data?.map((cafe) => {
        let cafeSavedI = MyFavouriteState.savedFav.findIndex(
          (cafeSaved) => cafeSaved.id === cafe.id
        );
        if (cafeSavedI >= 0) {
          console.log(
            "Cafe,",
            cafe.id,
            " cafe has found in saved Fav: ",
            MyFavouriteState.savedFav[cafeSavedI],
            MyFavouriteState.savedFav[cafeSavedI].maids
          );
          let favedMaids = cafe.maids.map((maid) => {
            let maidSavedI = MyFavouriteState.savedFav[
              cafeSavedI
            ].maids.findIndex((maidIdSaved) => maidIdSaved === maid.id);
            if (maidSavedI >= 0) {
              // console.log("Maid,", maid.id, " maid has found in saved Fav");
              return { ...maid, isFavourite: true };
            } else {
              // console.log("Maid,", maid.id, " maid not found in saved Fav");
              return { ...maid, isFavourite: false };
            }
          });
          // console.log("Faved Maids")
          shuffleArray(favedMaids);
          return { ...cafe, hasFavourites: true, maids: favedMaids };
        } else {
          console.log("Cafe,", cafe.id, " cafe not found in saved Fav");
          let maids = [...cafe.maids];
          shuffleArray(maids);
          return { ...cafe, hasFavourites: false, maids: maids };
        }
      });
      setMyFavouriteMaidsDisplay(result);
    }, [data, MyFavouriteState.savedFav])
  );

  const renderFavourite = ({ item }) => {
    return item.hasFavourites ? <DisplayFavCafeCard cafeData={item} /> : null;
  };

  return (
    <View
      style={{
        flex: 1,
        // backgroundColor: "pink",
        backgroundColor: "white",
      }}
    >
      <View
        style={{
          backgroundColor: "#f0f0f0",
          flex: 1,
        }}
      >
        {isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size={"large"} />
          </View>
        ) : null}

        {isError ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Error Occurs</Text>
          </View>
        ) : null}

        {!isLoading && !isError ? (
          <>
            <FlatList
              data={myFavouriteMaidsDisplay}
              renderItem={renderFavourite}
              keyExtractor={(item) => "cafe_" + item?.id}
              contentContainerStyle={{
                // backgroundColor: "#f0f0f0",
                // marginTop: 10,
                paddingVertical: 10,
                paddingHorizontal: 10,
                backgroundColor: "white",
                // flex: 1,
                minHeight: "100%",
              }}
              refreshControl={
                <RefreshControl
                  refreshing={isRefetchingFavourite}
                  onRefresh={refetchFavourite}
                />
              }
            />
          </>
        ) : null}
      </View>
    </View>
  );
};

export default MyFavouritScreen;
