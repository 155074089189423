import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  ActivityIndicator,
  RefreshControl,
  FlatList,
  Image,
  SectionList,
} from "react-native";
import { getRandomInt, shuffleArray } from "../../functions/functions";

import { useQuery } from "@tanstack/react-query";
import { WEEKDAYS_REF_EN } from "../../constants/weekdays";
import { API_URL } from "../../constants/api_url";
import MaidButton, {
  MaidDisplay,
} from "../../components/CafeProfile/MaidButton";
import Colors from "../../constants/Colors";
import { FontAwesome } from "@expo/vector-icons";

const OpeningHoursText = ({ openingHours }) => {
  const { start, end } = openingHours;
  const startText = () => {
    if (openingHours.start) {
      return <Text>{openingHours.start}</Text>;
    }
    return;
  };
  function connectionText() {
    if (openingHours.start && openingHours.end) {
      return <Text style={{ marginHorizontal: 3 }}>~</Text>;
    }
    return;
  }

  function endText() {
    if (openingHours.end) {
      return <Text>{openingHours.end}</Text>;
    }
    return;
  }

  return (
    <Text style={{ color: "black", fontWeight: "300", fontSize: 13 }}>
      時間 : {start ? <Text>{start}</Text> : null}
      {start && end ? <Text style={{}}> ~ </Text> : null}
      {end ? <Text>{end}</Text> : null}
    </Text>
  );
};

const DateButton = ({ item, selected, setSelectedDate }) => {
  const { date, index } = item;

  const handleSetDate = (date) => {
    setSelectedDate(index);
  };

  console.log(`Selected? ${date}_${index}`, selected);

  return (
    <TouchableOpacity
      onPress={() => {
        handleSetDate(index);
      }}
      // key={`date_${index}_${date.get}`}
    >
      <View
        style={{
          backgroundColor: "#fff",
          // backgroundColor: 'red',
          borderColor: "#000",
          borderRadius: 10,
          borderWidth: selected ? 3 : 1,
          marginHorizontal: 5,
          justifyContent: "center",
          minWidth: 80,
        }}
      >
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#000",
            paddingHorizontal: 8,
            paddingVertical: 5,
          }}
        >
          <Text
            style={{
              color:
                date.getDay() == 0 || date.getDay() == 6 ? "#ff0000" : "#000",
              fontSize: 23,
              textAlign: "center",
              fontWeight: "800",
            }}
          >
            {WEEKDAYS_REF_EN[date.getDay()].toUpperCase()}
          </Text>
        </View>
        <View
          style={{
            paddingHorizontal: 8,
            paddingVertical: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#000",
              fontSize: 40,
              textAlign: "center",
            }}
          >
            {date.getDate()}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const Dates = ({ today, date, day, selectedDate, setSelectedDate }) => {
  const DateScrollRef = useRef();
  const [numOfDates, setNumOfDates] = useState(7);
  const [dateArray, setDateArray] = useState([]);
  const [daysArray, setDaysArray] = useState([]);

  // const dates = [
  //   today.getDate(),
  //   today.getDate() + 1,
  //   today.getDate() + 2,
  //   today.getDate() + 3,
  //   today.getDate() + 4,
  //   today.getDate() + 5,
  //   today.getDate() + 6,
  // ];

  useEffect(() => {
    let toBeDateArray = [];

    for (let index = 0; index < numOfDates; index++) {
      let dayT = new Date(today);
      dayT.setDate(dayT.getDate() + index);
      toBeDateArray.push(dayT);
    }

    setDateArray(toBeDateArray);
    // dateArray.map((date, index) => {
    //   // console.log(`DATE Array ${index}: ${date.getDate()} ${date}`);
    // });
  }, [numOfDates]);

  useEffect(() => {
    let toBeDaysArray = [];

    for (let index = 0; index < numOfDates; index++) {
      let weekday = day + index;
      weekday = weekday > 6 ? weekday - 7 : weekday;
      toBeDaysArray[index] = weekday;
    }

    toBeDaysArray = toBeDaysArray.map((day, index) => WEEKDAYS_REF_EN[day]);
    setDaysArray(toBeDaysArray);
  }, [numOfDates]);

  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        borderBottomWidth: 1,
        paddingVertical: 5,
        borderBottomColor: "#c0c0c0",
      }}
    >
      <ScrollView
        style={{
          // backgroundColor: 'red',
          width: "100%",
          flexDirection: "row",
          paddingVertical: 10,
          // paddingHorizontal: 10,
          minHeight: 100,
        }}
        contentContainerStyle={{
          // justifyContent: "center",
          alignItems: "center",
        }}
        horizontal
        ref={DateScrollRef}
        // onLayout={() => DateScrollRef.current.scrollToStart()}

        onScroll={(event) => {
          // console.log(event.nativeEvent.contentOffset.x);
        }}
        scrollEventThrottle={400}
      >
        {/* ListHeaderComponent={ */}

        {/* <DateButtonContainer /> */}

        <View style={{ display: "none" }}>
          <TouchableOpacity
            onPress={() => {
              // setNumOfDates((prev) => prev + 7);
            }}
          >
            <View
              style={{
                paddingHorizontal: 10,
                minHeight: 100,
                minWidth: 50,
                // backgroundColor: "blue",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesome name="search" size={20} color={"#ccc"} />
            </View>
          </TouchableOpacity>
        </View>

        {dateArray.map((date, index) => {
          return (
            <DateButton
              key={`date_${date.getDate()}_${index}`}
              item={{ date: date, index: index }}
              setSelectedDate={setSelectedDate}
              selected={selectedDate == index ? true : false}
            />
          );
        })}
        <TouchableOpacity
          onPress={() => {
            setNumOfDates((prev) => prev + 7);
          }}
        >
          <View
            style={{
              paddingHorizontal: 10,
              minHeight: 100,
              minWidth: 50,
              // backgroundColor: "blue",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesome name="calendar-plus-o" size={32} color={"#ccc"} />
          </View>
        </TouchableOpacity>
        {/* <DateButtonContainer
          onPress={() => {
            setNumOfDates((prev) => prev + 7);
          }}
        /> */}
      </ScrollView>
    </View>
  );
};

const CafeButton = ({ cafe }) => {
  const navigation = useNavigation();
  const windowWidth = Dimensions.get("window").width;

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate("Cafe.Tab", {
          screen: "Cafe.CafeDetail",
          params: {
            cafeId: cafe.id,
            title: cafe.title,
          },
        });
      }}
      style={{ backgroundColor: "white" }}
    >
      <View
        style={[
          {
            width: windowWidth / 5,
            height: windowWidth / 5,
            // backgroundColor: "pink",
            borderRadius: windowWidth / 5 / 2,

            backgroundColor: "#a0a0a0",
            overflow: "hidden",
          },
          {
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.2,
            shadowRadius: 1.41,

            elevation: 2,
          },
        ]}
      >
        {cafe.logo ? (
          <Image
            source={{ uri: API_URL + "/" + cafe.logo }}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#a0a0a0",
            }}
          />
        ) : null}
      </View>

      {cafe.title ? (
        <Text
          style={{
            textAlign: "center",
            fontWeight: "500",
            marginVertical: 5,
          }}
          adjustsFontSizeToFit={true}
          numberOfLines={2}
        >
          {cafe.title}
        </Text>
      ) : null}
    </TouchableOpacity>
  );
};

const CafeCard = ({ cafe, children }) => {
  const windowWidth = Dimensions.get("window").width;

  return (
    <View
      style={{
        backgroundColor: "white",
        marginBottom: 15,
        flexDirection: "row",
        paddingHorizontal: 5,
        paddingVertical: 8,
        borderRadius: 5,
      }}
    >
      <View
        style={{
          marginRight: 15,
          maxWidth: windowWidth / 5,
        }}
      >
        <CafeButton cafe={cafe} />
      </View>
      <View
        style={{
          flex: 1,
          // backgroundColor: "pink",
        }}
      >
        {children}
      </View>
    </View>
  );
};

const RemainCafe = ({ allCafe, schedules }) => {
  const [cafeWithoutSchedules, setCafeWithoutSchedules] = useState([]);

  useEffect(() => {
    setCafeWithoutSchedules([]);
    allCafe.forEach((cafe) => {
      console.log("Cafes To Be checked: ", cafe);
      var flag = true;
      schedules.official.forEach((schedule) => {
        if (schedule.cafe_id === cafe.id) {
          flag = false;
        }
      });

      schedules.unofficial.forEach((schedule) => {
        if (schedule.cafe_id === cafe.id) {
          flag = false;
        }
      });
      if (flag) {
        setCafeWithoutSchedules((prev) => {
          var flag = true;
          prev.forEach((prevCafe) => {
            if (prevCafe.id == cafe.id) {
              flag = false;
            }
          });
          if (flag) {
            return [...prev, cafe];
          } else {
            return [...prev];
          }
        });
      }
    });
  }, [schedules]);

  console.log("Cafe without Schedules:", cafeWithoutSchedules);

  return (
    <View>
      <Text style={{ fontWeight: "bold", marginBottom: 5, fontSize: 16 }}>
        尚未更新
      </Text>
      {cafeWithoutSchedules
        ? cafeWithoutSchedules.map((cafe) => {
            return (
              <CafeCard cafe={cafe} key={cafe.id}>
                <View
                  style={{
                    justifyContent: "center",
                    // alignItems: "center",
                    // backgroundColor: "blue",
                    flex: 1,
                  }}
                >
                  <Text style={{ fontSize: 16 }}>尚未有更新</Text>
                </View>
              </CafeCard>
            );
          })
        : null}
    </View>
  );
};

const SchedulesScreen = () => {
  const [today, setToday] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(0);

  const [renderedCafe, setRenderedCafe] = useState([1]);

  const [isRefetchingSchedules, setIsRefetchingSchedules] = useState(false);

  const windowWidth = Dimensions.get("window").width;

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["schdeules", selectedDate],
    async () => await fetchSchedules(selectedDate)
  );

  const refetchSchedules = async () => {
    setIsRefetchingSchedules(true);
    try {
      await refetch();
    } catch (err) {
    } finally {
      setIsRefetchingSchedules(false);
    }
  };

  const fetchSchedules = async (selectedDate) => {
    const fetchLink = API_URL + "/api/v1/schedules/" + selectedDate;
    console.log("FetchLink: ", fetchLink);
    try {
      const response = await fetch(fetchLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const json = await response.json();
      // console.log(json.data);
      return json.data;
    } catch (error) {
      console.error("FETCH ERROR: ", error);
      throw new Error(error);
    } finally {
    }
  };

  useEffect(() => {
    console.log("Selected Date Changed!", selectedDate);
  }, [selectedDate]);

  const renderSchedule = ({ item }) => {
    // let openingHours = JSON.parse(item.opening_hours);
    let openingHours = item.opening_hours;

    let cafeMaids = [...item.maids];
    // console.log("Cafe Schedule Maids: ", cafeMaids, item.maid);

    console.log("USER:", item);

    let credit = item.user.credit_url ? (
      <TouchableOpacity onPress={() => {}}>
        <Text style={{ color: Colors.main }}>
          {item.user.credit_display ? item.user.credit_display : null}
        </Text>
      </TouchableOpacity>
    ) : (
      <Text>{item.user.credit_display ? item.user.credit_display : null}</Text>
    );

    shuffleArray(cafeMaids);

    return (
      <CafeCard cafe={item.cafe}>
        {item.event_title || !!openingHours.start || !!openingHours.end ? (
          <View
            style={{
              marginBottom: 5,
            }}
          >
            {item.event_title ? (
              <View
                style={{
                  borderBottomColor: "#e0e0e0",
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 15,
                      fontWeight: "500",
                      color: Colors.dark,
                    },
                  ]}
                >
                  {item.event_title}
                </Text>
              </View>
            ) : null}
            {!!openingHours.start || !!openingHours.end ? (
              <View>
                <OpeningHoursText openingHours={openingHours} />
              </View>
            ) : null}
          </View>
        ) : null}
        {/* Normal Maids */}
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {cafeMaids?.map((maid) => (
            <View style={{ marginBottom: 10, marginRight: 5 }} key={maid.id}>
              <MaidButton maid={maid} />
            </View>
          ))}
        </View>
        {/* Limited Maids */}
        {item.limited_maids ? (
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {item.limited_maids.map((maidGrp, index) => {
              console.log("Maid Grp: ", maidGrp);
              // console.log("Maid Grp Maids: ", JSON.parse(maidGrp.maids));
              // const limitedMaids = JSON.parse(maidGrp.maids);
              const limitedMaids = maidGrp.maids;

              return limitedMaids?.map((maid, mIndex) => {
                // console.log("Limited Maid: ", maid);
                return (
                  <View
                    style={{ marginBottom: 10, marginRight: 5 }}
                    key={
                      "limited_maid_" +
                      item.cafe_id +
                      "_" +
                      index +
                      "-" +
                      mIndex
                    }
                  >
                    <MaidDisplay
                      maid={{
                        title: maidGrp.name,
                        maid_name: maid.name,
                        color: maidGrp.color,
                      }}
                    />
                  </View>
                );
              });
            })}
          </View>
        ) : null}
        {item.unofficial
          ? credit
          : // <View>
            //   <Text>Updated By: {item.user?.credit_display}</Text>
            // </View>
            null}
      </CafeCard>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        // backgroundColor: "pink",
        backgroundColor: "white",
      }}
    >
      <View style={{}}>
        <Dates
          day={today.getDay()}
          today={today}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </View>
      <View
        style={{
          backgroundColor: "#f0f0f0",
          flex: 1,
        }}
      >
        {isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size={"large"} />
          </View>
        ) : null}

        {isError ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Error Occurs</Text>
          </View>
        ) : null}

        {/* {!isLoading && !isError ? (
          <>
            <FlatList
              data={data?.schedules?.official}
              renderItem={renderSchedule}
              keyExtractor={(item) =>
                "schedule" + item?.id + "_" + item?.cafe_id
              }
              contentContainerStyle={{
                backgroundColor: "#f0f0f0",
                // marginTop: 10,
                paddingVertical: 10,
                paddingHorizontal: 10,
              }}
              refreshControl={
                <RefreshControl
                  refreshing={isRefetchingSchedules}
                  onRefresh={refetchSchedules}
                />
              }
              ListFooterComponent={
                <RemainCafe
                  schedules={data?.schedules}
                  allCafe={data?.all_cafes}
                  data={data}
                />
              }
            />
          </>
        ) : null} */}

        {!isLoading && !isError ? (
          <>
            <SectionList
              // data={data?.schedules?.official}
              sections={[
                {
                  title: "官方更新",
                  data: [...data?.schedules?.official],
                },
                {
                  title: "第三方更新",
                  data: [...data?.schedules?.unofficial],
                },
              ]}
              renderItem={renderSchedule}
              renderSectionHeader={({ section: { title, data } }) => {
                // console.log("Section Header ", data);
                if (data.length <= 0) {
                  return null;
                }
                return (
                  <View style={{}}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginBottom: 5,
                        fontSize: 16,
                      }}
                    >
                      {title}
                    </Text>
                  </View>
                );
              }}
              keyExtractor={(item, index) =>
                "schedule" + item?.id + "_" + item?.cafe_id + "_" + "index"
              }
              contentContainerStyle={{
                backgroundColor: "#f0f0f0",
                // marginTop: 10,
                paddingVertical: 10,
                paddingHorizontal: 10,
              }}
              refreshControl={
                <RefreshControl
                  refreshing={isRefetchingSchedules}
                  onRefresh={refetchSchedules}
                />
              }
              ListFooterComponent={
                <RemainCafe
                  schedules={data?.schedules}
                  allCafe={data?.all_cafes}
                  // data={data}
                />
              }
            />
          </>
        ) : null}
      </View>
    </View>
  );
};

export default SchedulesScreen;
