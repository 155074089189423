import { memo, useMemo } from "react";
import { Dimensions, View } from "react-native";
import MaidButton from "../CafeProfile/MaidButton";
import CafeButton from "../Schedules/CafeButton";
import FavMaidButton from "./FavMaidButton";
import FavMaidsCard from "./FavMaidsCard";
import ToggleButton from "./ToggleButton";

const DisplayFavCafeCard = ({ cafeData }) => {
  const windowWidth = Dimensions.get("window").width;
  let cafeMaids = useMemo(() => [...cafeData.maids], [cafeData.maids]);

  return (
    <View
      style={{
        // backgroundColor: "white",
        // backgroundColor: "red",
        marginBottom: 15,
        flexDirection: "row",
        paddingHorizontal: 5,
        paddingVertical: 8,
        borderRadius: 5,
      }}
    >
      <View
        style={{
          marginRight: 15,
          maxWidth: windowWidth / 6 + 15,

          borderRightColor: "#f0f0f0",
          paddingRight: 15,
          borderRightWidth: 1,
        }}
      >
        {/* CAFE */}
        {/* <ToggleButton
          item={{
            id: cafeData.id,
            image: cafeData.logo,
            title: cafeData.title,
          }}
          disabled={true}
        /> */}
        <CafeButton
          cafe={{ id: cafeData.id, logo: cafeData.logo, title: cafeData.title }}
        />
      </View>
      <View
        style={{
          flex: 1,
          // backgroundColor: "pink",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {cafeMaids?.map((maid) => {
            // console.log("Called Mapping to rerender");

            return maid.isFavourite ? (
              <View style={{ marginBottom: 10, marginRight: 5 }} key={maid.id}>
                <MaidButton maid={maid} />
              </View>
            ) : null;
          })}
        </View>
      </View>
    </View>
  );
};

export default memo(DisplayFavCafeCard);
