import { memo, useMemo } from "react";
import { Dimensions, Image, Text, TouchableOpacity, View } from "react-native";
import { API_URL } from "../../constants/api_url";

const ToggleButton = ({ item, width, disabled, handlePress, isSelected }) => {
  const windowWidth = Dimensions.get("window").width;
  // const [selected, setSelected] = useState(
  //   isSelected ? true : false || disabled ? true : false
  // );

  const showButtonSelected = useMemo(() => isSelected, [isSelected]);

  const { title } = item;

  // console.log("Toggle Button Triggered", title);

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={[
        {
          backgroundColor: "white",
          opacity: showButtonSelected || disabled ? 1 : 0.3,
        },
        {},
      ]}
      disabled={disabled}
    >
      <View
        style={[
          {
            width: width ? width : windowWidth / 6,
            height: width ? width : windowWidth / 6,
            // backgroundColor: "pink",
            borderRadius: width ? width / 2 : windowWidth / 6 / 2,
            backgroundColor: "#a0a0a0",
            overflow: "hidden",
          },
          {
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.2,
            shadowRadius: 1.41,

            elevation: 2,
          },
        ]}
      >
        {item.image ? (
          <Image
            source={{ uri: API_URL + "/" + item.image }}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#a0a0a0",
            }}
          />
        ) : null}
      </View>
      {item.title ? (
        <Text
          style={{
            textAlign: "center",
            fontWeight: "500",
            marginVertical: 5,
          }}
          adjustsFontSizeToFit={true}
          numberOfLines={2}
        >
          {item.title}
        </Text>
      ) : null}
    </TouchableOpacity>
  );
};

export default memo(ToggleButton);
