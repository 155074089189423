import { getObjectData, storeObjectData } from "../../functions/functions";

export const initState = {
  savedFav: [],
  myFav: [],
};

const reducer = (prevState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "TOGGLE_FAV":
      console.log("TOGGLE_FAV called");
      // payload: cafeId, maidId
      const { cafeId, maidId } = payload;
      let prevList = [...prevState.myFav];
      // Check if cafe exist in array
      let cafeIndex = prevList.findIndex((cafe) => cafe.id == cafeId);
      // Cafe Exist
      if (cafeIndex >= 0) {
        // Check if Maid in list
        let maidIndex = prevList[cafeIndex].maids.findIndex(
          (maidI) => maidI == maidId
        );
        // console.log("Cafe Result :", prevList[cafeIndex]);

        // Maid Exist in array
        if (maidIndex >= 0) {
          // Remove pressed Maid from list
          prevList[cafeIndex].maids.splice(maidIndex, 1);
        } else {
          // Maid Not Exist in array
          prevList[cafeIndex].maids.push(maidId);
        }
      } else {
        // Cafe Not Exist
        // Create Cafe object with the Maid record
        prevList.push({
          id: cafeId,
          maids: [maidId],
        });
      }
      // return prevState;
      return { ...prevState, myFav: [...prevList] };

    case "INIT_FAV":
      console.log("INIT_FAV called");

      // payload.myFav
      // get My Favourite data from async
      // key: @my_favourite
      return { ...prevState, savedFav: [...payload.savedFav] };

    case "STORE_WORK_TO_SAVED":
      console.log("STORE_WORK_TO_SAVED called");

      // storeObjectData("@my_favourite", prevState.myFav);
      return { ...prevState, savedFav: [...prevState.myFav], myFav: [] };

    case "COPY_FOR_WORKING_ON":
      console.log("COPY_FOR_WORKING_ON called");
      return { ...prevState, myFav: [...prevState.savedFav] };

    default:
      return prevState;
  }
};
export default reducer;
