import { memo, useMemo } from "react";
import { Dimensions, View } from "react-native";
import FavMaidsCard from "./FavMaidsCard";
import ToggleButton from "./ToggleButton";

const FavCafeCard = ({ cafeData }) => {
  const windowWidth = Dimensions.get("window").width;
  let cafeMaids = useMemo(() => [...cafeData.maids], [cafeData.maids]);

  return (
    <View
      style={{
        // backgroundColor: "white",
        // backgroundColor: "red",
        marginBottom: 15,
        flexDirection: "row",
        paddingHorizontal: 5,
        paddingVertical: 8,
        borderRadius: 5,
      }}
    >
      <View
        style={{
          marginRight: 15,
          maxWidth: windowWidth / 6 + 15,

          borderRightColor: "#f0f0f0",
          paddingRight: 15,
          borderRightWidth: 1,
        }}
      >
        {/* CAFE */}
        <ToggleButton
          item={{
            id: cafeData.id,
            image: cafeData.logo,
            title: cafeData.title,
          }}
          disabled={true}
        />
      </View>
      <View
        style={{
          flex: 1,
          // backgroundColor: "pink",
        }}
      >
        <FavMaidsCard cafeId={cafeData.id} maidsData={cafeMaids} />
      </View>
    </View>
  );
};

export default memo(FavCafeCard);
