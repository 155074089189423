import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  Image,
  RefreshControl,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { useQuery } from "@tanstack/react-query";
import { API_URL } from "../constants/api_url";
import MaidInfoCard from "../components/MaidProfile/MaidInfoCard";
import { useEffect, useState } from "react";
import { HeaderBackButton } from "@react-navigation/elements";
import { StackActions } from "@react-navigation/native";

const MaidProfileScreen = ({ route, navigation }) => {
  const [isRefetchingMaid, setIsRefetchingMaid] = useState(false);
  const { maidId, title } = route.params;

  const windowWidth = Dimensions.get("window").width;

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["maid", maidId],
    async () => await fetchMaid(maidId)
  );

  useEffect(() => {
    console.log("Cafe ID", data?.cafe.id, data?.cafe.title);
    navigation.setOptions({
      headerLeft: (props) => {
        console.log("Route?", props);
        return (
          <HeaderBackButton
            onPress={() => {
              navigation.navigate("Cafe.CafeDetail", {
                cafeId: data?.cafe.id,
                title: data?.cafe.title,
              });
            }}
            tintColor={"#222"}
          />
        );
      },
    });
  }, [navigation, data]);

  const refetchMaid = async () => {
    setIsRefetchingMaid(true);
    try {
      await refetch();
    } catch (err) {
    } finally {
      setIsRefetchingMaid(false);
    }
  };

  const fetchMaid = async (id) => {
    const fetchLink = API_URL + "/api/v1/maids/" + id;
    console.log("FetchLink: ", fetchLink);
    try {
      const response = await fetch(fetchLink);
      const json = await response.json();
      console.log(json.data);
      return json.data;
    } catch (error) {
      console.error("FETCH ERROR: ", error);
      throw new Error(error);
    } finally {
    }
  };

  const renderImage = ({ item }) => {
    // item.image
    const { image } = item;
    // console.log("Maid Image", item);
    return (
      <View style={{ marginBottom: 20, paddingHorizontal: 10 }}>
        {/* <View style={{}}> */}
        {/* <Text>item</Text> */}
        <View
          style={{
            width: windowWidth - 20,
            height: windowWidth - 20,
            // borderRadius: 5,
            // overflow: "hidden",
            // flex: 1,
            backgroundColor: "#f0f0f0",
          }}
        >
          {image ? (
            <Image
              source={{ uri: API_URL + "/" + image }}
              style={{
                width: "100%",
                height: "100%",
                // flex: 1,
                // width: null,
                // height: null,
                // flex: 1,
                // borderRadius: 5,
                resizeMode: "contain",
              }}
              resizeMode="contain"
              // resizeMode=""
            />
          ) : null}
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        // backgroundColor: "pink",
        backgroundColor: "white",
      }}
    >
      {isLoading ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size={"large"} />
        </View>
      ) : null}

      {isError ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text>Error Occurs</Text>
        </View>
      ) : null}

      {!isLoading && !isError ? (
        <>
          <FlatList
            data={data?.maid.images}
            renderItem={renderImage}
            keyExtractor={(item) =>
              "maid_image" + item?.id + "_" + item?.cafe_id
            }
            ListHeaderComponent={
              <MaidInfoCard maid={data?.maid} schedules={data?.schedules} />
            }
            // refreshing={loading}
            contentContainerStyle={{
              // backgroundColor: "white",
              // marginTop: 10,
              flex: 1,
            }}
            refreshControl={
              <RefreshControl
                refreshing={isRefetchingMaid}
                onRefresh={refetchMaid}
              />
            }
          />
        </>
      ) : null}
    </View>
  );
};
const styles = StyleSheet.create({});

export default MaidProfileScreen;
