import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  Image,
  RefreshControl,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { API_URL } from "../../constants/api_url";

const CafeListScreen = ({ navigation }) => {
  const [isRefetchingCafes, setIsRefetchingCafes] = useState(false);

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["cafes"],
    async () => await fetchCafes()
  );

  const windowWidth = Dimensions.get("window").width;

  const refetchCafes = async () => {
    setIsRefetchingCafes(true);
    try {
      await refetch();
    } catch (err) {
    } finally {
      setIsRefetchingCafes(false);
    }
  };

  const fetchCafes = async () => {
    const fetchLink = API_URL + "/api/v1/cafes";
    console.log("FetchLink: ", fetchLink);
    try {
      const response = await fetch(fetchLink);
      const json = await response.json();
      console.log(json.data);
      return json.data;
    } catch (error) {
      console.error("Cafe List FETCH ERROR: ", error);
      throw new Error(error);
    } finally {
    }
  };

  console.log("CAFE LIST DATA:", data);
  const renderCafes = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("Cafe.Tab", {
            screen: "Cafe.CafeDetail",
            params: {
              cafeId: item.id,
              title: item.title,
            },
          });
        }}
      >
        <View
          style={{
            width: windowWidth / 2,
            // height: windowWidth / 2,
            padding: 10,
          }}
        >
          <View
            style={{
              width: windowWidth / 2 - 20,
              height: windowWidth / 2 - 20,
            }}
          >
            {item.logo ? (
              <Image
                source={{ uri: API_URL + "/" + item.logo }}
                style={{
                  width: "100%",
                  height: "100%",
                  flex: 1,
                  // borderRadius: 5,
                }}
                resizeMode="cover"
              />
            ) : (
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              ></View>
            )}
          </View>
          <Text
            style={{
              textAlign: "center",
              fontWeight: "500",
              marginVertical: 5,
            }}
            adjustsFontSizeToFit={true}
            numberOfLines={2}
          >
            {item.title}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      {/* <Text>Cafe List</Text> */}

      {isLoading ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size={"large"} />
        </View>
      ) : null}

      {isError ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text>Error Occurs</Text>
        </View>
      ) : null}

      {!isLoading && !isError ? (
        <>
          <FlatList
            data={data}
            renderItem={renderCafes}
            keyExtractor={(item) => "cafes_" + item?.id + "_" + item?.title}
            numColumns={2}
            // ListHeaderComponent={
            //   <MaidInfoCard maid={data?.maid} schedules={data?.schedules} />
            // }
            // refreshing={loading}
            contentContainerStyle={
              {
                // backgroundColor: "white",
                // marginTop: 10,
              }
            }
            refreshControl={
              <RefreshControl
                refreshing={isRefetchingCafes}
                onRefresh={refetchCafes}
              />
            }
          />
        </>
      ) : null}
    </View>
  );
};

export default CafeListScreen;
