import { useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  Dimensions,
  FlatList,
  Image,
  ImageBackground,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { useQuery } from "@tanstack/react-query";
import { AntDesign } from "@expo/vector-icons";

import Markdown from "react-native-markdown-display";

// import { API_BASE_URL } from "@env";
import { API_URL } from "../constants/api_url";
import Post from "../components/Timeline/Post";
import { useNavigation } from "@react-navigation/native";
import {
  OPENING_HOURS_WEEKDAYS_REF_TC,
  WEEKDAYS_REF_TC,
} from "../constants/weekdays";
import MainLayout from "../layouts/MainLayout";
import MetaInfoSection from "../components/CafeProfile/MetaInfoSection";
import CollapseSection from "../components/CollapseSection";
import CafeSchedules from "../components/CafeProfile/CafeSchedules";
import MaidSection from "../components/CafeProfile/MaidSection";
import Colors from "../constants/Colors";

const WeekdayOpeningHours = ({ opening_hours }) => {
  const [openingHours, setOpeningHours] = useState();
  let weekdays = [];

  useEffect(() => {
    // let ohArr = JSON.parse(opening_hours);
    let ohArr = opening_hours;

    const filteredOH = [];
    for (let index = 0; index < 8; index++) {
      const result = ohArr.filter((item) => item.weekday == index);
      // console.log("Weekday Filter", index, typeof result, result);
      if (result.length == 0) {
        // filteredOH.push([{ weekdays: index }]);
        filteredOH.push([]);
      } else {
        filteredOH.push(result);
      }
    }
    setOpeningHours(filteredOH);
  }, [opening_hours]);

  return (
    <CollapseSection
      title={"營業時間"}
      titleStyle={{
        // backgroundColor: "#F5BB40",
        // backgroundColor: "#F5C260",
        // backgroundColor: "#F5B75E",
        // backgroundColor: "#F5B75E",
        backgroundColor: Colors.main,
        color: "#404040",
      }}
      containerStyle={{ marginBottom: 20 }}
      // contentContainerStyle={{ backgroundColor: "yellow" }}
    >
      <View
        style={{
          paddingVertical: 10,
          paddingHorizontal: 10,
        }}
      >
        <View
          style={
            {
              // width: "100%",
              // backgroundColor: "lightgreen",
            }
          }
        >
          {openingHours
            ? openingHours.map((openingHour, index) => {
                console.log("OPENING HOUR PRINT OUT", openingHour);
                return (
                  <View
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                    key={index}
                  >
                    <View
                      style={{
                        justifyContent: "center",
                        marginRight: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {OPENING_HOURS_WEEKDAYS_REF_TC[index]}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        // flex: 1,
                      }}
                    >
                      {openingHour.length <= 0 ? (
                        <View>
                          <Text>休息</Text>
                        </View>
                      ) : (
                        openingHour.map((oh, i) => {
                          return (
                            <View
                              style={{
                                // paddingBottom: 3,
                                flexDirection: "row",
                                flexWrap: "wrap",
                                // backgroundColor: "lightgreen",
                                marginRight: 10,
                              }}
                              key={i}
                            >
                              <Text
                                style={
                                  {
                                    // textAlign: "center",
                                  }
                                }
                              >
                                {oh.start}
                              </Text>
                              <Text
                                style={{
                                  // textAlign: "center"
                                  marginHorizontal: 5,
                                }}
                              >
                                ~
                              </Text>
                              <Text
                                style={
                                  {
                                    // textAlign: "center"
                                  }
                                }
                              >
                                {oh.end}
                              </Text>
                            </View>
                          );
                        })
                      )}
                    </View>
                  </View>
                );
              })
            : null}
        </View>
      </View>
    </CollapseSection>
  );
};

const CafeInfoSection = ({ cafe }) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  return (
    <ImageBackground
      source={{ uri: API_URL + "/" + cafe.logo }}
      resizeMode="cover"
      style={{
        width: "100%",
        // height: "100%",
      }}
    >
      <View
        style={{
          backgroundColor: "rgba(0,0,0,0.3)",
          paddingBottom: 20,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            paddingVertical: 12,
            paddingHorizontal: 10,
            minHeight: windowHeight / 3,
            justifyContent: "flex-end",
            // alignItems: "flex-end",
          }}
        >
          {/* <ImageBackground
        source={{ uri: API_URL + "/" + cafe.logo }}
        resizeMode="cover"
        style={{}}
        // imageStyle={{
        //   width: "100%",
        //   height: "100%",
        // }}
      > */}
          {/* CAFE LOGO */}
          <View
            style={{
              // width: "100%",
              // height: "100%",
              flexDirection: "column",
              justifyContent: "flex-end",
              paddingBottom: 20,
              // marginBottom: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
                paddingBottom: 10,
              }}
            >
              <View
                style={{
                  width: windowWidth / 3,
                  height: windowWidth / 3,
                  // borderRadius: 5,
                  // borderColor: "#5e5e5e",
                  // borderColor: "#a0a0a0",
                  // borderWidth: 0.5,
                  overflow: "hidden",
                  borderRadius: windowWidth / 3 / 2,
                  backgroundColor: "#a0a0a0",
                }}
              >
                {cafe.logo ? (
                  <Image
                    source={{ uri: API_URL + "/" + cafe.logo }}
                    style={{
                      width: "100%",
                      height: "100%",
                      flex: 1,
                      // borderRadius: 5,
                    }}
                    resizeMode="cover"
                  />
                ) : null}
              </View>
            </View>
            {/* CAFE TITLE */}

            {/* <View
              style={{
                paddingHorizontal: 12,
                paddingVertical: 10,
                // flex: 1,
                backgroundColor: "rgba(255,255,255,0.9)",
                // opacity: 0.7,
                // backgroundOp
                // marginBottom: 20,
              }}
            >
              <View
                style={{
                  width: "100%",
                  // flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "blue",
                  opacity: 1,
                }}
              >
                <Text
                  style={{ color: "#505050", fontSize: 20, fontWeight: "500" }}
                > */}
            {/* {cafe?.title} */}
            {/* </Text>
              </View>
            </View> */}
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

const CafeInfoCard = ({ data }) => {
  const windowWidth = Dimensions.get("window").width;

  const navigation = useNavigation();

  return (
    <View
      style={[
        {
          backgroundColor: "#fce7eb",
          zIndex: 1,
        },
        {
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,

          elevation: 5,
        },
      ]}
    >
      <CafeInfoSection cafe={data} />
      <View
        style={{
          paddingHorizontal: 10,
          paddingTop: 10,
          paddingBottom: 20,

          backgroundColor: "white",

          borderTopStartRadius: 20,
          borderTopEndRadius: 20,
          // zIndex: 2,
          marginTop: -20,
        }}
      >
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Text style={{ color: "#505050", fontSize: 20, fontWeight: "500" }}>
            {data?.title}
          </Text>
        </View>

        {data.info ? <MetaInfoSection info={data.info} /> : null}
        {data.opening_hours ? (
          <WeekdayOpeningHours opening_hours={data.opening_hours} />
        ) : null}
        {data.maids ? <MaidSection maids={data.maids} /> : null}
      </View>

      <View
        style={{
          // backgroundColor: "blue",
          backgroundColor: "white",
          paddingHorizontal: 10,
          paddingTop: 10,
          paddingBottom: 5,
        }}
      >
        <Text
          style={{
            fontSize: 19,
            textAlign: "center",
          }}
        >
          當值表
        </Text>
      </View>
    </View>
  );
};

const CafeProfileScreen = ({ route, navigation }) => {
  const [isRefetchingCafe, setIsRefetchingCafe] = useState(false);

  const { cafeId, title } = route.params;

  const windowWidth = Dimensions.get("window").width;

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["cafe", cafeId],
    async () => await fetchCafe(cafeId)
  );

  const refetchCafe = async () => {
    setIsRefetchingCafe(true);
    try {
      await refetch();
    } catch (err) {
    } finally {
      setIsRefetchingCafe(false);
    }
  };

  const fetchCafe = async (id) => {
    const fetchLink = API_URL + "/api/v1/cafes/" + id;
    console.log("FetchLink: ", fetchLink);
    try {
      const response = await fetch(fetchLink);
      const json = await response.json();
      console.log(json.data);
      return json.data;
    } catch (error) {
      console.error("FETCH ERROR: ", error);
      throw new Error(error);
    } finally {
    }
  };

  return (
    <MainLayout>
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: "white",
          // marginBottom: 50,
          // paddingBottom: 50,
        }}
        refreshControl={
          <RefreshControl
            refreshing={isRefetchingCafe}
            onRefresh={refetchCafe}
          />
        }
      >
        {/* <View
      style={{
        flex: 1,
        backgroundColor: "white",
      }}
    > */}
        {isLoading ? (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size={"large"} />
          </View>
        ) : null}
        {isError ? (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Text>Error Occurs</Text>
          </View>
        ) : null}
        {!isLoading && !isError ? (
          <>
            <CafeInfoCard data={data.cafe} />

            <View
              style={{
                minHeight: 500,
                // backgroundColor: "lightblue",
                paddingTop: 10,
                paddingBottom: 100,
              }}
            >
              <CafeSchedules schedules={data.schedules} />
            </View>
          </>
        ) : null}
      </ScrollView>
    </MainLayout>
  );
};

const styles = StyleSheet.create({});
export default CafeProfileScreen;

// <FlatList
//   data={data?.posts}
//   renderItem={renderPost}
//   // keyExtractor={(item) => "post_" + item?.id + "_" + item?.cafe_id}
//   ListHeaderComponent={<CafeInfoCard data={data?.cafe} />}
//   refreshing={loading}
//   contentContainerStyle={{
//     backgroundColor: "white",
//     // marginTop: 10,
//   }}
// />
