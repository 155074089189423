import { useState } from "react";
import { Dimensions, View } from "react-native";
import Colors from "../../constants/Colors";
import { shuffleArray } from "../../functions/functions";
import CollapseSection from "../CollapseSection";
import MaidButton from "./MaidButton";

const MaidSection = ({ maids }) => {
  shuffleArray(maids);

  let x = undefined;

  shuffleArray(x);

  return (
    <CollapseSection
      title={"所有女僕"}
      titleStyle={{
        backgroundColor: "#F5B75E",
        backgroundColor: Colors.main,
        // fontWeight: "bold",
      }}
    >
      <View
        style={[
          {
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            // backgroundColor: "blue",
            paddingHorizontal: 10,
            paddingVertical: 10,
          },
        ]}
      >
        {maids
          ? maids?.map((maid) => {
              return <MaidButton maid={maid} key={maid.id} />;
            })
          : null}
      </View>
    </CollapseSection>
  );
};

export default MaidSection;
