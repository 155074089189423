import { useRef, useState } from "react";
import { Animated, View, Text, TouchableOpacity } from "react-native";

import { AntDesign } from "@expo/vector-icons";

const CollapseSection = ({
  containerStyle,
  title,
  titleStyle,
  contentContainerStyle,
  children,
}) => {
  const [sectionVisible, setSectionVisible] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerHeightSet, setContainerHeightSet] = useState(false);

  const rollAnim = useRef(new Animated.Value(0)).current;

  const rollDown = () => {
    let initHeight = Animated.timing(rollAnim, {
      toValue: containerHeight,
      duration: 1000,
      useNativeDriver: false,
    }).start();
  };
  const rollUp = () => {
    Animated.timing(rollAnim, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: false,
    }).start();
  };

  const handleSectionToggle = () => {
    setSectionVisible((prev) => {
      if (prev) {
        rollUp();
      } else {
        rollDown();
      }
      return !prev;
    });
  };

  return (
    <View style={[{}, containerStyle]}>
      <View
        style={[
          {
            overflow: "hidden",
            backgroundColor: "white",

            borderTopStartRadius: 10,
            borderTopEndRadius: 10,
            // zIndex: 0,
            zIndex: 2,
          },
          {
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.2,
            shadowRadius: 1.41,
            elevation: 2,
          },
        ]}
      >
        <TouchableOpacity onPress={handleSectionToggle}>
          <Text
            style={[
              {
                color: "#505050",
                textAlign: "center",
                fontWeight: "500",
                fontSize: 20,
                paddingVertical: 5,
              },
              titleStyle,
            ]}
          >
            {title ? title : ""}
            {"  "}
            {sectionVisible ? (
              <AntDesign name="down" size={18} color="#505050" />
            ) : (
              <AntDesign name="up" size={18} color="#505050" />
            )}
          </Text>
        </TouchableOpacity>
      </View>
      <Animated.View
        style={[
          {
            overflow: "hidden",

            borderBottomStartRadius: 10,
            borderBottomEndRadius: 10,
            // borderColor: "#E3E5E7",
            // borderWidth: 1,

            // backgroundColor: "blue",
            // marginTop: 5,
            // marginTop: -2,
            zIndex: 1,
          },
          {
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.2,
            shadowRadius: 1.41,
            elevation: 2,
          },
          {
            // height: rollAnim,
            height: containerHeightSet ? rollAnim : "auto",
          },
        ]}
        onLayout={(e) => {
          if (!containerHeightSet) {
            var { x, y, width, height } = e.nativeEvent.layout;
            // console.log("Width and Hieght of Maid Section is", width, height);
            rollAnim.current = height;
            setContainerHeight(height);
            setContainerHeightSet(true);
          }
        }}
      >
        <View
          style={[
            {
              width: "100%",
              backgroundColor: "white",
            },
            contentContainerStyle,
          ]}
        >
          {children ? children : null}
        </View>
      </Animated.View>
    </View>
  );
};

export default CollapseSection;
