import { useNavigation } from "@react-navigation/native";
import { Text, TouchableOpacity, View, Image, Dimensions } from "react-native";
import { API_URL } from "../../constants/api_url";

const CafeButton = ({ cafe }) => {
  const navigation = useNavigation();
  const windowWidth = Dimensions.get("window").width;

  const { id, title, logo } = cafe;

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate("Cafe.Tab", {
          screen: "Cafe.CafeDetail",
          params: {
            cafeId: id,
            title: title,
          },
        });
      }}
      style={{ backgroundColor: "white" }}
    >
      <View
        style={[
          {
            width: windowWidth / 6,
            height: windowWidth / 6,
            // backgroundColor: "pink",
            borderRadius: windowWidth / 6 / 2,

            backgroundColor: "#a0a0a0",
            overflow: "hidden",
          },
          {
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.2,
            shadowRadius: 1.41,

            elevation: 2,
          },
        ]}
      >
        {logo ? (
          <Image
            source={{ uri: API_URL + "/" + logo }}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#a0a0a0",
            }}
          />
        ) : null}
      </View>

      {title ? (
        <Text
          style={{
            textAlign: "center",
            fontWeight: "500",
            marginVertical: 5,
          }}
          adjustsFontSizeToFit={true}
          numberOfLines={2}
        >
          {title}
        </Text>
      ) : null}
    </TouchableOpacity>
  );
};

export default CafeButton;
