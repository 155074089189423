import { Dimensions, Text, View, Image, ImageBackground } from "react-native";
import { API_URL } from "../../constants/api_url";
import Colors from "../../constants/Colors";
import MetaInfoSection from "../CafeProfile/MetaInfoSection";
import CollapseSection from "../CollapseSection";
import WeekdayCard from "../Schedules/WeekdayCard";

const OpeningHoursText = ({ openingHours }) => {
  const { start, end } = openingHours;
  const startText = () => {
    if (openingHours.start) {
      return <Text>{openingHours.start}</Text>;
    }
    return;
  };
  function connectionText() {
    if (openingHours.start && openingHours.end) {
      return <Text style={{ marginHorizontal: 3 }}>~</Text>;
    }
    return;
  }

  function endText() {
    if (openingHours.end) {
      return <Text>{openingHours.end}</Text>;
    }
    return;
  }

  return (
    <View style={{ color: "black" }}>
      {start ? (
        <Text
          style={{
            color: "black",
            textAlign: "center",
            // backgroundColor: "blue",
            marginVertical: 0,
            paddingVertical: 0,
          }}
        >
          {start}
        </Text>
      ) : null}
      {start && end ? (
        <Text
          style={{
            color: "black",
            textAlign: "center",
          }}
        >
          至
        </Text>
      ) : null}
      {end ? (
        <Text
          style={{
            color: "black",
            textAlign: "center",
          }}
        >
          {end}
        </Text>
      ) : null}
    </View>
  );
};

const MaidInfoBackground = ({ children, imageBackground }) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  if (imageBackground) {
    return (
      <ImageBackground
        source={{ uri: API_URL + "/" + imageBackground }}
        resizeMode="cover"
        style={{
          width: "100%",
          height: "100%",
          flex: 1,
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          {children}
        </View>
      </ImageBackground>
    );
  }

  return <View>{children}</View>;
};

const MaidIcon = ({ image }) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  return (
    // {/* CAFE LOGO */}
    <View
      style={{
        width: windowWidth / 5,
        height: windowWidth / 5,
        // borderRadius: 5,
        // borderColor: "#5e5e5e",
        // borderColor: "#a0a0a0",
        // borderWidth: 0.5,
        overflow: "hidden",
        borderRadius: windowWidth / 4 / 2,
        backgroundColor: "#a0a0a0",
      }}
    >
      {image ? (
        <Image
          source={{ uri: API_URL + "/" + image }}
          style={{
            width: "100%",
            height: "100%",
            flex: 1,
            // borderRadius: 5,
          }}
          resizeMode="cover"
        />
      ) : null}
    </View>
  );
};

const MaidInfoSection = ({ maid }) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  return (
    // <MaidInfoBackground
    //   imageBackground={maid.thumbnail_image ? maid.thumbnail_image : null}
    // >
    <View
      style={{
        flexDirection: "row",
        // justifyContent: "center",
        alignItems: "center",
        // paddingHorizontal: 10,
        paddingVertical: 10,
      }}
    >
      <View
        style={{
          marginRight: 10,
        }}
      >
        {maid.thumbnail_image ? (
          <MaidIcon image={maid.thumbnail_image} />
        ) : null}
      </View>
      <View style={{}}>
        {maid.name ? (
          <View style={{}}>
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              {maid.name}
            </Text>
          </View>
        ) : null}

        {maid.cafe_title ? (
          <View>
            <Text>{maid.cafe_title}</Text>
          </View>
        ) : null}
      </View>
    </View>
    // </MaidInfoBackground>
  );
};

const MaidMetaSection = ({ maidMeta }) => {};

const MaidInfoCard = ({ maid, schedules }) => {
  console.log("Maid Info", maid);
  return (
    <View>
      <MaidInfoBackground
        imageBackground={maid.thumbnail_image ? maid.thumbnail_image : null}
      >
        <View
          style={[
            {
              marginTop: 200,
              // backgroundColor: "lightblue",
              backgroundColor: "#fff",
              marginHorizontal: 10,
              marginBottom: 30,
              borderRadius: 10,
            },
            {
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,

              elevation: 5,
            },
          ]}
        >
          <View
            style={{
              paddingHorizontal: 10,
            }}
          >
            {maid ? <MaidInfoSection maid={maid} /> : null}

            {maid.info ? <MetaInfoSection info={maid.info} /> : null}
          </View>
          {/* {maid ?} */}
        </View>
      </MaidInfoBackground>
      <View
        style={{
          marginTop: -20,
        }}
      >
        <View
          style={{
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            // height: 200,
            backgroundColor: "white",
            marginBottom: 20,
          }}
        >
          <View
            style={{
              paddingVertical: 10,
              paddingHorizontal: 10,
            }}
          >
            <CollapseSection
              title={"當值日"}
              titleStyle={{
                backgroundColor: "",
                backgroundColor: "#F5B75E",
                backgroundColor: Colors.main,
                color: "#404040",
              }}
              contentContainerStyle={
                {
                  // zIndex: 3,
                  // marginBottom: 3,
                }
              }
            >
              <View
                style={{
                  // paddingVertical: 10,
                  // paddingHorizontal: 10,
                  // marginBottom: 10,
                  marginVertical: 10,
                  marginHorizontal: 10,
                }}
              >
                <View
                  style={{
                    // justifyContent: "center",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    // backgroundColor: "blue",
                  }}
                >
                  {schedules.map((schedule, index) => {
                    let curDate = new Date(schedule.schedule_date);
                    // let openingHours = JSON.parse(schedule.opening_hours);
                    let openingHours = schedule.opening_hours;
                    return (
                      <View
                        style={{ width: "25%" }}
                        key={"maid_schedule_" + index}
                      >
                        <View
                          style={
                            {
                              // width: "25%",
                            }
                          }
                        >
                          <WeekdayCard date={curDate} />
                        </View>
                        <View style={{ marginBottom: 5 }}>
                          {schedule.event_title ? (
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#505050",
                                fontWeight: "500",
                                maxWidth: "100%",
                                textAlign: "center",
                              }}
                            >
                              {schedule.event_title}
                            </Text>
                          ) : // <OpeningHoursText
                          null}
                          {openingHours ? (
                            <OpeningHoursText openingHours={openingHours} />
                          ) : null}
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </CollapseSection>
          </View>
        </View>
      </View>
    </View>
  );
};

export default MaidInfoCard;
