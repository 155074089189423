import { memo, useCallback, useContext, useState } from "react";
import { Dimensions, View } from "react-native";
import MyFavouriteStore from "../../stores/MyFavourite";
import ToggleButton from "./ToggleButton";

const FavMaidButton = ({ cafeId, maidData }) => {
  const windowWidth = Dimensions.get("window").width;
  const { state: MyFavouritedState, dispatch: MyFavouriteDispatch } =
    useContext(MyFavouriteStore.Context);

  const [maid, setMaid] = useState(maidData);

  const { id, thumbnail_image: image, name, isFavourite } = maid;

  const [selected, setSelected] = useState(isFavourite);

  // console.log("Fav Maid Button triggered", cafeId, id, name);

  const handleMaidButtonPressed = useCallback(
    (cafeId, id) => {
      setSelected((prev) => !prev);
      MyFavouriteDispatch({
        type: "TOGGLE_FAV",
        payload: { cafeId: cafeId, maidId: id },
      });
    },
    [cafeId, id]
  );

  return (
    <View style={{ marginBottom: 10, marginRight: 5 }} key={id}>
      <ToggleButton
        width={((windowWidth / 6) * 5 - 15) / 5}
        item={{ id: id, image: image, title: name }}
        handlePress={() => handleMaidButtonPressed(cafeId, id)}
        disabled={false}
        isSelected={selected}
      />
    </View>
  );
};

export default memo(FavMaidButton);
