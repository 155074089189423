import { useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  Image,
  RefreshControl,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { API_URL } from "../../constants/api_url";
import colors from "../../constants/Colors";
import { Feather } from "@expo/vector-icons";
import { getObjectData, storeObjectData } from "../../functions/functions";
import MyFavouriteStore from "../../stores/MyFavourite";
import ToggleButton from "../../components/MyFavourite/ToggleButton";
import FavCafeCard from "../../components/MyFavourite/FavCafeCard";
import { HeaderBackButton } from "@react-navigation/elements";

function checkMaidExist(targetArr, cafeId, maidId) {
  let target = [...targetArr];
  let cafeIndex = target.findIndex((cafe) => cafe.id == cafeId);
  // Cafe Exist
  if (cafeIndex >= 0) {
    // Check if Maid in list
    let maidIndex = target[cafeIndex].maids.findIndex(
      (maidI) => maidI == maidId
    );
    console.log("Cafe Result :", target[cafeIndex]);
    // Maid Exist in array
    if (maidIndex >= 0) {
      return true;
      // Remove pressed Maid from list
      // target[cafeIndex].maids.splice(maidIndex, 1);
    } else {
      // Maid Not Exist in array
      return false;
      // target[cafeIndex].maids.push(maidId);
    }
  } else {
    // Cafe Not Exist
    return false;
    // Create Cafe object with the Maid record
    // target.push({
    //   id: cafeId,
    //   maids: [maidId],
    // });
  }
}

const AddMyFavouritScreen = ({ navigation }) => {
  const windowWidth = Dimensions.get("window").width;

  const [selectedMaids, setSelectedMaids] = useState([]);

  const [isRefetchingFavourite, setIsRefetchingFavourite] = useState(false);
  // @ts-expect-error
  const { state: MyFavouriteState, dispatch: MyFavouriteDispatch } = useContext(
    MyFavouriteStore.Context
  );

  const [storedFav, setStoredFav] = useState();
  const [orgainzedFavResult, setOrgainzedFavResult] = useState();

  // Handle Heade Right Button
  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        return (
          <HeaderBackButton
            onPress={() => {
              // navigation.reset("Cafe.Tab", { screen: "Cafe.List" });
              navigation.goBack();
            }}
            tintColor={"#222"}
          />
        );
      },
      headerRight: () => {
        return (
          <TouchableOpacity
            style={{ marginRight: 10 }}
            onPress={() => {
              // Save Preference to AsyncStorage
              // console.log("About to Save:", MyFavouriteState.myFav);
              storeObjectData("@my_favourite", MyFavouriteState.myFav);
              //Go back to My Favourite
              MyFavouriteDispatch({ type: "STORE_WORK_TO_SAVED" });
              navigation.goBack();
            }}
          >
            <Feather name="save" color={"#222"} size={28} />
          </TouchableOpacity>
        );
      },
    });
  }, [navigation, MyFavouriteState.myFav]);

  const refetchFavourite = async () => {
    setIsRefetchingFavourite(true);
    try {
      await refetch();
    } catch (err) {
    } finally {
      setIsRefetchingFavourite(false);
    }
  };

  const fetchFavourite = async () => {
    const fetchLink = API_URL + "/api/v1/my-favourite";
    console.log("FetchLink: ", fetchLink);
    try {
      const response = await fetch(fetchLink);
      const json = await response.json();
      return json.data;
    } catch (error) {
      console.error("Cafe List FETCH ERROR: ", error);
      throw new Error(error);
    } finally {
    }
  };

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ["favourites"],
    queryFn: fetchFavourite,
  });

  useEffect(() => {
    let result = data?.map((cafe) => {
      let cafeSavedI = MyFavouriteState.savedFav.findIndex(
        (cafeSaved) => cafeSaved.id === cafe.id
      );
      if (cafeSavedI >= 0) {
        console.log(
          "Cafe,",
          cafe.id,
          " cafe has found in saved Fav: ",
          MyFavouriteState.savedFav[cafeSavedI],
          MyFavouriteState.savedFav[cafeSavedI].maids
        );
        let favedMaids = cafe.maids.map((maid) => {
          let maidSavedI = MyFavouriteState.savedFav[
            cafeSavedI
          ].maids.findIndex((maidIdSaved) => maidIdSaved === maid.id);

          if (maidSavedI >= 0) {
            // console.log("Maid,", maid.id, " maid has found in saved Fav");
            return { ...maid, isFavourite: true };
          } else {
            // console.log("Maid,", maid.id, " maid not found in saved Fav");
            return { ...maid, isFavourite: false };
          }
        });
        // console.log("Faved Maids")
        return { ...cafe, maids: favedMaids };
      } else {
        console.log("Cafe,", cafe.id, " cafe not found in saved Fav");
        return cafe;
      }
    });
    setOrgainzedFavResult(result);
  }, [data]);

  const renderFavourite = ({ item }) => {
    return <FavCafeCard cafeData={item} />;
  };

  return (
    <View
      style={{
        flex: 1,
        // backgroundColor: "pink",
        backgroundColor: "white",
      }}
    >
      <View
        style={{
          backgroundColor: "#f0f0f0",
          flex: 1,
        }}
      >
        {isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size={"large"} />
          </View>
        ) : null}

        {isError ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Error Occurs</Text>
          </View>
        ) : null}

        {!isLoading && !isError ? (
          <>
            <FlatList
              data={orgainzedFavResult}
              renderItem={renderFavourite}
              keyExtractor={(item) => "cafe_" + item?.id}
              contentContainerStyle={{
                backgroundColor: "#fff",
                // flex: 1,
                paddingVertical: 10,
                paddingHorizontal: 10,
                minHeight: "100%",
              }}
              refreshControl={
                <RefreshControl
                  refreshing={isRefetchingFavourite}
                  onRefresh={refetchFavourite}
                />
              }
            />
          </>
        ) : null}
      </View>
    </View>
  );
};

export default AddMyFavouritScreen;
